import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Periodo } from '../../../app/domains/periodo';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Cliente } from '../../../app/domains/cliente';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-periodo-edit',
  templateUrl: './periodo-edit.component.html',
  styleUrls: ['./periodo-edit.component.css'],
  standalone: false,
})
export class periodoEditComponent implements OnInit {
  form: UntypedFormGroup = null as any;
  enviando = false;

  @Input() periodo: Periodo = null as any;
  @Input() selectedCliente: Cliente = null as any;
  @Input() editMode: boolean = false;
  displayperiodo: boolean = false;

  @Output()
  updateList = new EventEmitter();

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      id: [],
      tipo: ['', Validators.required],
      data_pagamento: [''],
      maximo_horas: [0],
      descricao: ['', Validators.required],
      data_inicio: ['', Validators.required],
      data_fim: [''],
      valor_hora: [0, Validators.required],
      porcentagem_imposto: [0, Validators.required],
      data_emissao_nota: [undefined, Validators.required],
      solicitante: [false],
      data_solicitacao: [false],
      envia_relatorio: [false],
      checa_hora: [true],
      informa_chamado: [true],
      mostra_abonos: [true],
      cliente_id: [0],
      filial_id: [0],
      nome_filial: [''],
      nosso_numero: [''],
      dias_liberados: [8],
    });
    this.form.get('maximo_horas')?.disable();
    this.form.get('data_emissao_nota');
    this.form.get('data_fim')?.disable();
    this.form.get('nosso_numero')?.disable();
    this.form.get('nome_filial')?.disable();
  }

  updateForm(periodo: Periodo) {
    this.form.setValue({
      id: periodo.id,
      data_inicio: new Date(periodo.data_inicio),
      data_fim: periodo.data_fim ? new Date(periodo.data_fim) : '',
      descricao: periodo.descricao,
      tipo: periodo.tipo,
      maximo_horas: periodo.maximo_horas,
      valor_hora: periodo.valor_hora,
      porcentagem_imposto: periodo.porcentagem_imposto,
      cliente_id: periodo.cliente_id,
      data_pagamento: periodo.data_pagamento
        ? new Date(periodo.data_pagamento)
        : '',
      data_emissao_nota: periodo.data_emissao_nota
        ? new Date(periodo.data_emissao_nota)
        : '',
      dias_liberados: periodo.dias_liberados,
      nosso_numero: periodo.nosso_numero,
      filial_id: periodo.filial_id,
      solicitante: ((periodo as any).solicitante = 1) ? true : false,
      data_solicitacao: ((periodo as any).data_solicitacao = 1) ? true : false,
      envia_relatorio: ((periodo as any).envia_relatorio = 1) ? true : false,
      checa_hora: ((periodo as any).checa_hora = 1) ? true : false,
      informa_chamado: ((periodo as any).informa_chamado = 1) ? true : false,
      mostra_abonos: ((periodo as any).mostra_abonos = 1) ? true : false,
      nome_filial: periodo.nome_filial ? periodo.nome_filial : '',
    });
    this.onChangeTipo();
    //  se não tem atendimentos não deixa fechar
    if (periodo.quantidade_atendimentos > 0) {
      this.form.get('data_fim')?.enable();
    }
  }

  onDialogHide() {
    this.periodo = null as any;
  }

  onSubmit() {
    const tempperiodo = <Periodo>{};

    tempperiodo.tipo = this.form.value.tipo ? this.form.value.tipo : 'Aberto';
    tempperiodo.maximo_horas = this.form.value.maximo_horas;
    tempperiodo.data_pagamento = this.form.value.data_pagamento;
    tempperiodo.descricao = this.form.value.descricao;
    tempperiodo.data_inicio = this.form.value.data_inicio;
    if (this.form.value.data_fim) {
      tempperiodo.data_fim = this.form.value.data_fim;
    }
    tempperiodo.valor_hora = +this.form.value.valor_hora;
    tempperiodo.porcentagem_imposto = +this.form.value.porcentagem_imposto;
    tempperiodo.cliente_id = this.selectedCliente?.id as any;
    tempperiodo.solicitante = this.form.value.solicitante;
    tempperiodo.dias_liberados = this.form.value.dias_liberados;
    tempperiodo.data_solicitacao = this.form.value.data_solicitacao;
    tempperiodo.envia_relatorio = this.form.value.envia_relatorio;
    tempperiodo.checa_hora = this.form.value.checa_hora;
    tempperiodo.informa_chamado = this.form.value.informa_chamado;
    tempperiodo.mostra_abonos = this.form.value.mostra_abonos;
    tempperiodo.filial_id = this.form.value.filial_id;
    tempperiodo.nosso_numero = this.form.value.nosso_numero;
    tempperiodo.data_emissao_nota =
      this.form.getRawValue().data_emissao_nota === ''
        ? undefined
        : this.form.getRawValue().data_emissao_nota;
    // tempperiodo.Status = "Aberto";

    let url = environment.apiUrl + '/period';
    let metodo;
    if (this.editMode) {
      tempperiodo.id = +this.form.value.id;
      metodo = this.http.put(url + '/' + tempperiodo.id, tempperiodo);
    } else {
      metodo = this.http.post(url, tempperiodo);
    }

    this.enviando = true;
    metodo.subscribe(
      () => {
        this.enviando = false;
        this.updateList.emit(this.selectedCliente.id);
        this.hide();
      },
      (error) => {
        this.enviando = false;
        console.log(error);
      }
    );
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir o periodo?',
      accept: () => {
        this.enviando = true;
        const url = environment.apiUrl + '/period/' + this.form.value.Id;
        this.http.delete(url).subscribe(
          () => {
            this.enviando = false;
            this.updateList.emit(this.selectedCliente.id);
            this.hide();
            this.messageService.add({
              severity: 'success',
              summary: 'Exclusão efetuada com sucesso',
              detail: 'Sua exclusão foi realizada.',
            });
          },
          (error) => {
            this.enviando = false;
            console.log(error);

            this.messageService.add({
              severity: 'error',
              summary: 'Falha na exclusão',
              detail: 'Via MessageService',
            });
          }
        );
      },
    });
  }

  hide() {
    this.displayperiodo = false;
  }

  onChangeTipo() {
    if (this.form.value.tipo === 'Fechado') {
      this.form.get('maximo_horas')?.setValidators([Validators.required]);
      this.form.get('maximo_horas')?.enable();
    } else {
      this.form.get('maximo_horas')?.clearValidators();
      this.form.get('maximo_horas')?.disable();
      this.form.get('maximo_horas')?.setValue(0);
    }
    this.form.updateValueAndValidity();
  }
}
