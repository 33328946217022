<p-toast></p-toast>
<p-dialog
  [contentStyle]="{ overflow: 'visible' }"
  header="Manutenção de período"
  [responsive]="true"
  [(visible)]="displayperiodo"
  (onAfterHide)="onDialogHide()"
  [modal]="true"
>
  <form
    *ngIf="displayperiodo"
    [formGroup]="form"
    id="mainForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="field grid flex">
      <div class="col-12 flex">
        <div class="col-md-3 col-sm-12">
          <label htmlFor="descricao">Tipo: </label>
          <div class="">
            <p-dropdown
              formControlName="tipo"
              optionLabel="name"
              optionValue="name"
              [options]="[{ name: 'Aberto' }, { name: 'Fechado' }]"
              (onChange)="onChangeTipo()"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label htmlFor="descricao">Maximo de Horas: </label>
          <div class="p-inputgroup">
            <input
              id="maximo_horas"
              type="number"
              formControlName="maximo_horas"
              pInputText
            />
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label>Data Pagamento: ({{ selectedCliente.dia_vencimento }}) </label>
          <div class="p-inputgroup">
            <p-datepicker
              dateFormat="dd/mm/yy"
              formControlName="data_pagamento"
            ></p-datepicker>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label htmlFor="descricao">Descrição: </label>
          <div class="p-inputgroup">
            <input
              id="descricao"
              type="text"
              formControlName="descricao"
              pInputText
            />
          </div>
        </div>
      </div>
      <div class="col-12 flex">
        <div class="col-md-3 col-sm-12">
          <label>Valor Hora:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <input type="text" formControlName="valor_hora" pInputText />
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label>Porc.de Imposto: </label>

          <div class="p-inputgroup">
            <input
              type="text"
              formControlName="porcentagem_imposto"
              pInputText
              placeholder="%"
            />
            <span class="p-inputgroup-addon">%</span>
          </div>
        </div>

        <div class="col-md-3 col-sm-12">
          <label>Data Inicio: </label>
          <div class="p-inputgroup">
            <p-datepicker
              dateFormat="dd/mm/yy"
              formControlName="data_inicio"
            ></p-datepicker>
          </div>
        </div>

        <div class="col-md-3 col-sm-12">
          <label>Data Fim:</label>
          <div class="p-inputgroup">
            <p-datepicker
              dateFormat="dd/mm/yy"
              formControlName="data_fim"
            ></p-datepicker>
          </div>
        </div>
      </div>
      <div class="col-12 flex">
        <div class="col-md-2 col-sm-12">
          <label>solicitante:</label>
          <div class="p-inputgroup">
            <p-inputSwitch formControlName="solicitante"></p-inputSwitch>
          </div>
        </div>
        <div class="col-md-2 col-sm-12">
          <label>Data Solicitação:</label>
          <div class="p-inputgroup">
            <p-inputSwitch formControlName="data_solicitacao"></p-inputSwitch>
          </div>
        </div>
        <div class="col-md-2 col-sm-12">
          <label>Envia Relatorio:</label>
          <div class="p-inputgroup">
            <p-inputSwitch formControlName="envia_relatorio"></p-inputSwitch>
          </div>
        </div>
        <div class="col-md-2 col-sm-12">
          <label>Verifica Hora:</label>
          <div class="p-inputgroup">
            <p-inputSwitch formControlName="checa_hora"></p-inputSwitch>
          </div>
        </div>
        <div class="col-md-2 col-sm-12">
          <label>Informa chamado:</label>
          <div class="p-inputgroup">
            <p-inputSwitch formControlName="informa_chamado"></p-inputSwitch>
          </div>
        </div>
        <div class="col-md-2 col-sm-12">
          <label>Mostra Abonos:</label>
          <div class="p-inputgroup">
            <p-inputSwitch formControlName="mostra_abonos"></p-inputSwitch>
          </div>
        </div>
      </div>
      <div class="col-12 flex">
        <div class="col-md-3 col-sm-12">
          <label>Emissao NF:</label>
          <div class="p-inputgroup">
            <p-datepicker
              dateFormat="dd/mm/yy"
              formControlName="data_emissao_nota"
            ></p-datepicker>
          </div>
        </div>
        <div class="col-md-2 col-sm-12">
          <label>Dias Lançamento de OS:</label>
          <div class="p-inputgroup">
            <input type="text" formControlName="dias_liberados" pInputText />
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label>Filial:</label>
          <div class="p-inputgroup">
            <input type="text" formControlName="nome_filial" pInputText />
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <label>Nosso Número:</label>
          <div class="p-inputgroup">
            <input type="text" formControlName="nosso_numero" pInputText />
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-template #footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button
        type="submit"
        pButton
        icon="pi pi-check"
        form="mainForm"
        label="Salvar"
        [disabled]="enviando"
      ></button>
    </div>
  </ng-template>
</p-dialog>

<p-confirmDialog></p-confirmDialog>
