import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth.service';

@Component({
  templateUrl: './login-pass.component.html',
  standalone: false,
})
export class LoginPassComponent implements OnInit {
  usuario: string = '';
  senha: string = '';
  senhaConfirmacao: string = '';
  token: string = '';
  recuperar = false;
  loading = false;
  tokenEnviado = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private service: MessageService
  ) {
    const usuario = localStorage.getItem('usuario-protheus');
    if (usuario) {
      this.usuario = usuario;
    }
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      //this.router.navigate(['/atendimento/']);
    }
  }

  EnviaToken() {
    this.loading = true;
    this.senha = '';
    this.senhaConfirmacao = '';
    if (!this.usuario || this.usuario === '') {
      this.service.add({
        severity: 'warn',
        summary: 'Usuário vazio',
        detail: 'Preencha o usuário para receber um token!',
      });
    } else {
      this.authService.EnviaToken(
        this.usuario,
        this.service,
        (): void => {
          this.tokenEnviado = true;
          this.loading = false;
        },
        (): void => {
          this.loading = false;
        }
      );
    }
  }

  TrocarSenha() {
    if (!this.token || this.token === '') {
      this.service.add({
        severity: 'warn',
        summary: 'Token vazio',
        detail: 'Preencha o token para trocar a senha!',
      });
    } else if (!this.senha || this.senha === '') {
      this.service.add({
        severity: 'warn',
        summary: 'Senha vazia',
        detail: 'Preencha a senha para trocar a senha!',
      });
    } else if (this.senha !== this.senhaConfirmacao) {
      this.service.add({
        severity: 'warn',
        summary: 'Senha vazia',
        detail: 'As senhas são diferentes!',
      });
    } else {
      this.authService.TrocarSenha(
        this.usuario,
        this.token,
        this.senha,
        this.service,
        (): void => {
          localStorage.setItem('usuario', this.usuario);
          this.service.add({
            detail: 'Senha trocada com sucesso!',
            severity: 'success',
          });
          this.router.navigate(['/atendimento/']);
        }
      );
    }
  }

  Logar() {
    if (!this.usuario || this.usuario === '') {
      this.service.add({
        severity: 'warn',
        summary: 'Usuário vazio',
        detail: 'Preencha o usuário para entrar!',
      });
    } else if (!this.senha || this.senha === '') {
      this.service.add({
        severity: 'warn',
        summary: 'Senha vazia',
        detail: 'Preencha a senha para entrar!',
      });
    } else {
      this.authService.loginSenha(
        this.usuario,
        this.senha,
        this.service,
        (): void => {
          localStorage.setItem('usuario', this.usuario);
          this.service.add({
            detail: 'Login efetuado com sucesso!',
            severity: 'success',
          });
          this.router.navigate(['/atendimento/']);
        }
      );
    }
  }
}
