import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { LoginPassComponent } from './login-pass/login-pass.component';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  declarations: [LoginPassComponent],
  imports: [
    CommonModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    InputNumberModule,
    AvatarModule,
    AvatarGroupModule,
    ProgressSpinnerModule,
    FormsModule,
    ToastModule,
    FloatLabelModule,
    InputMaskModule,
    RouterModule.forChild([{ path: 'login', component: LoginPassComponent }]),
  ],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
