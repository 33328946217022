<div class="container mt-5">
  <h2>Dados Para Assinatura</h2>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label htmlFor="inputNome">Nome</label>
      <input
        type="text"
        class="form-control"
        id="inputNome"
        placeholder="Digite seu nome"
        [(ngModel)]="name"
      />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label htmlFor="inputCargo">Cargo</label>
      <input
        type="text"
        class="form-control"
        id="inputCargo"
        placeholder="Digite seu cargo"
        [(ngModel)]="cargo"
      />
    </div>
    <div class="form-group col-md-6">
      <label htmlFor="inputTelefone">Telefone com DDD</label>
      <input
        type="tel"
        class="form-control"
        id="inputTelefone"
        placeholder="Digite seu telefone"
        [(ngModel)]="phone"
      />
    </div>
  </div>
  <div class="form-group">
    <label htmlFor="inputEmail">Email</label>
    <input
      type="email"
      class="form-control"
      id="inputEmail"
      placeholder="Digite seu email"
      [(ngModel)]="mail"
    />
  </div>
</div>

<br />
<br />
<button (click)="baixa()">Baixar</button>

<br />
<br />

<app-modelo-assinatura
  id="modelo-assinatura"
  [name]="name"
  [cargo]="cargo"
  [phone]="phone"
  [mail]="mail"
></app-modelo-assinatura>
