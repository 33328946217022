import { Atendimento } from './atendimento';

export class Periodo {
  id!: number;
  descricao!: string;
  status!: string;
  valor_hora!: number;
  porcentagem_imposto!: number;
  data_inicio!: string;
  data_fim!: string;
  cliente_id!: string;
  icon!: string;
  quantidade_atendimentos!: number;
  tipo!: String;
  maximo_horas!: number;
  data_pagamento!: string;
  data_emissao_nota!: string;
  solicitante!: Boolean;
  data_solicitacao!: Boolean;
  envia_relatorio!: Boolean;
  checa_hora!: Boolean;
  informa_chamado!: Boolean;
  mostra_abonos!: Boolean;
  duracao!: number;
  dias_liberados!: number;
  filial_id!: number;
  nome_filial!: string;
  nosso_numero!: string;

  atendimentos: Atendimento[] = [];
}
