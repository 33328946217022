<div class="flex h-screen">
  <div
    style="align-items: center"
    class="w-full h-full text-center flex justify-content-between col-12"
  >
    <div class="flex flex-column align-items-center col" *ngIf="!recuperar">
      <div class="mb-3">
        <h2>Entre para ter acesso ao portal</h2>
      </div>

      <p-floatlabel variant="in" class="m-2">
        <input
          pInputText
          id="usuario"
          type="usuario"
          [(ngModel)]="usuario"
          class="w-20rem"
        />
        <label for="usuario">E-mail</label>
      </p-floatlabel>

      <p-floatlabel variant="in" class="m-2">
        <p-password
          id="senha"
          [(ngModel)]="senha"
          class="w-20rem"
          [style.display]="'grid'"
          (keyup.enter)="Logar()"
          [feedback]="false"
          [toggleMask]="true"
          [fluid]="true"
        />
        <label for="senha">Senha</label>
      </p-floatlabel>
      <button
        pButton
        (click)="Logar()"
        label="Entrar"
        type="button"
        class="w-20rem"
      ></button>
      <a (click)="recuperar = true; token = ''; tokenEnviado = false"
        >Recuperar sua senha?</a
      >
    </div>

    <div
      class="flex flex-column align-items-center gap-4 col-4"
      *ngIf="recuperar && !tokenEnviado"
    >
      <div class="mb-3">
        <h2>Recuperar senha</h2>
      </div>

      <p-floatlabel variant="in" class="m-2">
        <input
          pInputText
          id="usuario"
          type="usuario"
          [(ngModel)]="usuario"
          class="w-20rem"
        />
        <label for="usuario">E-mail</label>
      </p-floatlabel>
      <button
        pButton
        (click)="EnviaToken()"
        *ngIf="!tokenEnviado"
        label="Receber Token no e-mail"
        [disabled]="!usuario || loading"
        type="button"
        class="w-20rem"
      ></button>
      <a (click)="recuperar = false">Voltar para o login</a>
    </div>

    <div
      class="flex flex-column align-items-center gap-4 col-4"
      *ngIf="recuperar && tokenEnviado"
    >
      <div class="mb-3">
        <h2>Informe o Token que recebeu no seu e-mail</h2>
      </div>
      <p-floatlabel variant="in" class="m-2">
        <input
          pInputText
          id="usuario"
          type="usuario"
          [(ngModel)]="usuario"
          class="w-20rem"
        />
        <label for="usuario">E-mail</label>
      </p-floatlabel>

      <p-floatlabel variant="in" class="m-2">
        <p-inputmask
          id="token"
          [(ngModel)]="token"
          mask="999-999"
          [unmask]="true"
          class="w-20rem"
          [style.display]="'grid'"
          autocomplete="off"
        />
        <label for="token">Token</label>
      </p-floatlabel>

      <p-floatlabel variant="in" class="m-2">
        <p-password
          id="senha"
          [(ngModel)]="senha"
          class="w-20rem"
          [style.display]="'grid'"
          [toggleMask]="true"
          [fluid]="true"
        />
        <label for="senha">Nova Senha</label>
      </p-floatlabel>

      <p-floatlabel variant="in" class="m-2">
        <p-password
          id="senhaConfirmacao"
          [(ngModel)]="senhaConfirmacao"
          class="w-20rem"
          [style.display]="'grid'"
          [feedback]="false"
          [toggleMask]="true"
          [fluid]="true"
        />
        <label for="senhaConfirmacao">Confirme a Senha</label>
      </p-floatlabel>

      <button
        pButton
        (click)="TrocarSenha()"
        label="Trocar senha"
        [disabled]="!token || !senha || loading"
        type="button"
        class="w-20rem"
      ></button>
      <a (click)="recuperar = false">Voltar para o login</a>

      <p class="text-color-secondary font-semibold"></p>
      <p class="text-color-secondary font-semibold"></p>
      <p class="text-color-secondary font-semibold"></p>
    </div>
    <div
      class="flex-column justify-content-between align-items-center bg-cover bg-norepeat col-8"
    >
      <div class="mt-auto mb-auto">
        <img style="max-width: 80%" src="../../assets/images/auth/login.svg" />
      </div>
      <div class="flex align-items-center gap-5">
        <span class="text-white font-semibold"></span>
      </div>
    </div>
  </div>

  <p-toast></p-toast>
</div>
