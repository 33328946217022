import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService, SortMeta } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { rateios_tecnico, Tecnico } from '../../domains/tecnico';
import { SelectItem } from 'primeng/select';
import { Cliente } from '../../domains/cliente';
import { Periodo } from '../../domains/periodo';
import { Usuario } from '../../domains/usuarios';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Result } from '../../domains/result';

@Component({
  selector: 'app-tecnico-list',
  templateUrl: './tecnico-list.component.html',
  styleUrls: ['./tecnico-list.component.css'],
  standalone: false,
})
export class TecnicoListComponent implements OnInit {
  cols: any[] = [];
  multiSortMeta: SortMeta[] = [
    { field: 'Ativo', order: -1 },
    { field: 'Nome', order: 1 },
  ];

  tecnicos: Tecnico[] = [];
  clonedTecnicos: { [s: string]: Tecnico } = {};
  clonedRateios: { [s: string]: rateios_tecnico } = {};

  sortOptions: SelectItem[] = [];

  sortKey: string = '';

  sortField: string = '';

  sortOrder: number = 0;

  expandedRows = {};

  showList = false;

  clientes: Cliente[] = [];
  periodos: Periodo[] = [];
  usuarios: Usuario[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private authorizeService: AuthService
  ) {}

  ngOnInit() {
    this.cols = [{ field: 'Nome', header: 'Nome' }];

    this.http.get<Result<Periodo[]>>(environment.apiUrl + '/period').subscribe(
      (res) => {
        this.periodos = res.data;
      },
      (error) => {
        console.log(error);
        if (error.status === 401) {
          this.authorizeService.logout();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Não foi possível carregar os períodos',
          });
        }
      }
    );

    this.http
      .get<Result<Cliente[]>>(environment.apiUrl + '/customer')
      .subscribe(
        (res) => {
          this.clientes = res.data;
        },
        (error) => {
          console.log(error);
          if (error.status === 401) {
            this.authorizeService.logout();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Não foi possível carregar os clientes',
            });
          }
        }
      );

    this.getTecnicos();
  }

  getTecnicos() {
    this.http
      .get<Result<Usuario[]>>(environment.apiUrl + 'api/usuario')
      .subscribe(
        (res) => {
          this.usuarios = res.data;
          this.http
            .get<Result<Tecnico[]>>(environment.apiUrl + 'api/tecnico')
            .subscribe(
              (res) => {
                if (res.data.length < 2) {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Você não tem permissão!',
                  });
                  this.router.navigate(['/atendimento']);
                } else {
                  this.tecnicos = res.data;
                  this.getRateios();
                }
              },
              (error) => {
                console.log(error);
                if (error.status === 401) {
                  this.authorizeService.logout();
                } else {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Não foi possível carregar os tecnicos',
                  });
                }
              }
            );
        },
        (error) => {
          console.log(error);
          if (error.status === 401) {
            this.authorizeService.logout();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Não foi possível carregar os usuários',
            });
          }
        }
      );
  }

  getRateios() {
    const url = environment.apiUrl + '/prorate';
    this.http.get<Result<rateios_tecnico[]>>(url).subscribe(
      (res) => {
        this.tecnicos.forEach((x) => {
          x.rateios = res.data
            .filter((y: rateios_tecnico) => {
              return y.tecnico_id === x.id;
            })
            .sort((a: rateios_tecnico, b: rateios_tecnico) => {
              var o1 = a.cliente_id;
              var o2 = b.cliente_id;

              var p1 = a.periodo_id;
              var p2 = b.periodo_id;

              var q1 = a.tecnico_recebedor_id;
              var q2 = b.tecnico_recebedor_id;

              if (o1 < o2) return -1;
              if (o1 > o2) return 1;
              if (p1 < p2) return -1;
              if (p1 > p2) return 1;
              if (q1 < q2) return -1;
              if (q1 > q2) return 1;
              return 0;
            });
          x.usuario = this.usuarios.find((y) => {
            return y.tecnico_id === x.id;
          });
        });

        setTimeout(() => {
          this.showList = true;
        }, 10);
      },
      (error) => {
        console.log(error);

        if (error.status === 401) {
          this.authorizeService.logout();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Erro na carga de Rateios',
          });
        }
      }
    );
  }

  AddRateio(tecnico: Tecnico) {
    const rateio = new rateios_tecnico();
    rateio.tecnico_id = tecnico.id;
    tecnico.rateios.push(rateio);
  }

  onRowEditInit(tecnico: Tecnico) {
    this.clonedTecnicos[tecnico.id as any] = { ...tecnico };
  }

  onRowEditSave(tecnico: Tecnico) {
    const list: Observable<any>[] = [];

    const obs = this.http.put<Result<Tecnico>>(
      environment.apiUrl + '/technician',
      tecnico
    );
    list.push(obs);

    let obs2;
    if (tecnico.usuario) {
      obs2 = this.http.put<Result<Usuario>>(
        environment.apiUrl + '/users/' + tecnico.usuario.id + '/' + tecnico.id,
        {}
      );
      list.push(obs2);
    } else {
      if (
        this.clonedTecnicos[tecnico.id as any].usuario &&
        this.clonedTecnicos[tecnico.id as any]?.usuario?.id
      ) {
        obs2 = this.http.put<Result<Usuario>>(
          environment.apiUrl +
            '/users/' +
            this.clonedTecnicos[tecnico.id as any]?.usuario?.id +
            '/0',
          {}
        );
        list.push(obs2);
      }
    }

    forkJoin(list).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Tecnico Atualizado',
        });
        this.clonedTecnicos = {};
        this.getTecnicos();
      },
      (error) => {
        console.log(error);

        if (error.status === 401) {
          this.authorizeService.logout();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Tecnico não atualizado',
          });
        }
      }
    );
  }

  onRowEditCancel(tecnico: Tecnico, index: number) {
    this.tecnicos[index] = this.clonedTecnicos[tecnico.id as any];
    delete this.clonedTecnicos[tecnico.id as any];
  }

  onRowEditInitRateio(rateio: rateios_tecnico) {
    console.log(rateio);
  }

  onRowEditSaveRateio(rateio: rateios_tecnico) {
    const url = environment.apiUrl + '/prorate';
    this.http.put<Result<rateios_tecnico>>(url, rateio).subscribe(
      () => {
        delete this.clonedRateios[rateio.id as any];
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Rateio atualizado',
        });
        this.getRateios();
      },
      (error) => {
        console.log(error);

        if (error.status === 401) {
          this.authorizeService.logout();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Rateio não atualizado',
          });
        }
      }
    );
  }

  onRowEditCancelRateio(
    rateio: rateios_tecnico,
    tecnico: Tecnico,
    index: number
  ) {
    tecnico.rateios[index] = this.clonedRateios[rateio.id as any];
    delete this.clonedRateios[rateio.id as any];
  }

  onDeleteRateio(rateio: rateios_tecnico) {
    if (rateio.id) {
      const url = environment.apiUrl + '/prorate' + rateio.id;
      this.http.delete<Result<rateios_tecnico>>(url).subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Rateio excluído',
          });
          this.getRateios();
        },
        (error) => {
          console.log(error);
          if (error.status === 401) {
            this.authorizeService.logout();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Rateio não excluído',
            });
          }
        }
      );
    } else {
      this.getRateios();
    }
  }

  Nome(lista: any[], id: number) {
    return lista.find((x) => {
      return x.value === id;
    })?.label;
  }
  NomeById(lista: any[], id: number) {
    return lista.find((x) => {
      return x.Id === id;
    })?.Nome;
  }

  openNew() {
    this.showList = false;
    this.tecnicos.unshift({
      nome: '',
      id: 0,
      ativo: true,
      chave_pix: '',
      rateios: [],
    });
    setTimeout(() => {
      this.showList = true;
    }, 2);
  }

  onDeleteTecnico(tecnico: Tecnico) {
    if (tecnico.id) {
      const url = environment.apiUrl + '/technician/' + tecnico.id;
      this.http.delete<Tecnico[]>(url).subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Tecnico excluído',
          });
          this.getTecnicos();
        },
        (error) => {
          console.log(error);
          if (error.status === 401) {
            this.authorizeService.logout();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Tecnico não excluído',
            });
          }
        }
      );
    } else {
      this.tecnicos = this.tecnicos.filter((x) => {
        return x !== tecnico;
      });
    }
  }
}
