import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiUrl)) {
      const hrd =
        request && request.headers && request.headers['headers']
          ? request.headers['headers']
          : request.headers;

      const hrdNew = {};
      hrd.keys().forEach((key: any) => {
        (hrdNew as any)[key] = hrd.get(key);
      });

      (hrdNew as any)[
        'Authorization'
      ] = `Bearer ${this.authService.getJwtToken()}`;

      request = Object.assign(request, {
        headers: new HttpHeaders(hrdNew),
      });
    }
    return next.handle(request).pipe(
      catchError((error): Observable<any> | Observable<unknown> => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (!request.url.match(/users\/login/g)) {
            this.authService.logout();
            this.router.navigate(['/auth']);
          }
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
