<!--==========================
    Intro Section
  ============================-->
<section id="intro" class="clearfix">
  <div class="container">
    <div class="intro-img">
      <img src="../../assets/images/intro-img.svg" alt="" class="img-fluid" />
    </div>

    <div class="intro-info">
      <h2>
        Nós vamos fornecer as melhores<br /><span>soluções</span><br />para seu
        negócio!
      </h2>
      <div>
        <a href="#about" class="btn-get-started scrollto">Quem somos</a>
        <a href="#services" class="btn-services scrollto">Nossos Serviços</a>
      </div>
    </div>
  </div>
</section>
<main id="main">
  <section id="about">
    <div class="container">
      <header class="section-header">
        <h3>Quem Somos</h3>
        <p>
          Uma empresa com especialização em consultoria de software,
          automatização de processos e desenvolvimentos, com soluções simples,
          rápidas e de alta qualidade.
        </p>
      </header>

      <div class="row about-container">
        <div class="col-lg-6 content order-lg-1 order-2">
          <p class="text-justify">
            Temos equipes especializadas nos softwares Protheus&trade;,
            PowerBI&trade;, Angular, trabalhamos com sistema de versionamento de
            código, automatização de deploy, gerenciamento de serviços,
            desenvolvimentos, consultorias, treinamentos.
          </p>

          <div class="icon-box wow fadeInUp">
            <div class="icon">
              <i class="fa fa-shopping-bag"></i>
            </div>
            <h4 class="title"><a href="">Missão</a></h4>
            <p class="description text-justify">
              Fornecer soluções e serviços que promovam o crescimento de nossos
              clientes.
            </p>
          </div>

          <div class="icon-box wow fadeInUp" data-wow-delay="0.2s">
            <div class="icon"><i class="fa fa-eye"></i></div>
            <h4 class="title"><a href="">Visão</a></h4>
            <p class="description text-justify">
              Nosso objetivo é ser a empresa de referência como consultora e
              desenvolvedora de soluções e produtos, sendo reconhecida pela
              qualidade de nossas entregas.
            </p>
          </div>

          <div class="icon-box wow fadeInUp" data-wow-delay="0.4s">
            <div class="icon"><i class="fa fa-heart"></i></div>
            <h4 class="title"><a href="">Valores</a></h4>
            <p class="description">
              Satisfação do cliente.
              <br />
              Valorização de nossos colaboradores.
              <br />
              Busca incessante por inovações.
            </p>
          </div>
        </div>

        <div class="col-lg-6 background order-lg-2 order-1 wow fadeInUp">
          <img
            src="../../assets/images/about-img.svg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row about-extra">
        <div class="col-lg-6 wow fadeInUp">
          <img
            src="../../assets/images/about-extra-1.svg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
          <h4>Quais os diferenciais da BS Code?</h4>
          <p class="text-justify">
            Somos amantes de evolução! Buscamos a cada dia proporcionar para
            nossos clientes o que há de mais robusto e simples.
          </p>
          <p class="text-justify">
            Hoje temos um grande foco em trazer para nossos clientes as
            evoluções que foram criadas em outras linguagens de programação e
            processos.
          </p>
          <p class="text-justify">
            Estamos trabalhando para conseguir criar todo um mecanismo para que
            o DEVOPS seja viável em projetos do Protheus&trade;, da mesma forma
            que já implementamos em outros projetos. Como um dos grandes pilares
            desta cultura é a automatização de processos estamos, cada dia mais,
            automatizando os processos para otimizar processos e recursos.
          </p>
        </div>
      </div>

      <div class="row about-extra">
        <div class="col-lg-6 wow fadeInUp order-1 order-lg-2">
          <img
            src="../../assets/images/about-extra-2.svg"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
          <h4>Torne o Protheus&trade; uma API robusta</h4>
          <p class="text-justify">
            Alguns de nosssos clientes buscam uma interface mais amigável,
            nesses casos nossa equipe pode construir aplicações para
            funcionalidade específicas de forma rápida e simples com uma
            interface WEB (ANGULAR) e utilizando o Protheus&trade; como API.
            Assim, é possível a utilização do seu sistema Protheus&trade; de
            forma a aproveitar todas as evoluções, controles e integrações, mas
            sem a necessidade de utilizar a interface do próprio
            Protheus&trade;.
          </p>
          <p class="text-justify">
            Esses clientes conseguem pagar um valor mais baixo com as licenças
            por poder comprar as licenças TOTVS&trade; I e ainda reduz o
            processamento do servidor de aplicação balanceando os processamentos
            do servidor com os clientes conectados.
          </p>
          <p class="text-justify">
            Com o uso de API's é possível que você faça outros sistemas enviarem
            comandos para o próprio Protheus&trade; e garantir que todos os
            controles, validações e reflexos de sua mudança vão ocorrer como se
            um usuário o tivesse feito manualmente, mas de forma automatizada.
          </p>
          <h4>Contrua Portais e Ferramentas</h4>
          <p class="text-justify">
            Alguns de nosssos clientes buscam uma interface mais amigável,
            nesses casos nossa equipe pode construir aplicações para
            funcionalidade específicas de forma rápida e simples com uma
            interface WEB (ANGULAR) e utilizando o Protheus&trade; como API.
            Assim, é possível a utilização do seu sistema Protheus&trade; de
            forma a aproveitar todas as evoluções, controles e integrações, mas
            sem a necessidade de utilizar a interface do próprio
            Protheus&trade;.
          </p>
        </div>
      </div>

      <div class="row about-extra">
        <div class="col-lg-6 wow fadeInUp">
          <img
            src="../../assets/images/extra/Scffold (1).png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
          <h4>Tenha o Máximo do PowerBI&trade;</h4>
          <p class="text-justify">
            Atualmente empresas que desejam estar a frente na tomada de decições
            precisa ter uma análise de dados, rápida, precisa e eficiente.
            Decisões tomadas com dados errados levam ao erro, decisões tomadas
            com dados desatualizados levam a decisões atrasadas.
          </p>
          <p class="text-justify">
            Seu sistema de BI pode te dar toda a informação de forma correta e
            em tempo real. Nossos profissionais podem auxiliar em cada passo
            para que sua visão gerencial seja a melhor para cada decisão.
          </p>
          <h4>PowerBI&trade; e Protheus&trade; uma Combinação Poderosa</h4>
          <p class="text-justify">
            Imagine ter uma das mais robustas ferramentas de BI e um dos mais
            robustos ERP's do mercado.
          </p>
          <p class="text-justify">
            Quando se tem um ERP com a dimensão do Protheus&trade; combinado com
            o PowerBI&trade; você tem um potencial fora do comum.
          </p>
          <p>
            Conectando o PowerBI&trade; à uma planilha de excel é possível ter
            um resultado muito positivo, agora imagine o resultado possível
            quando falamos de conectá-lo à uma ERP com mais de
            <b>14 MIL tabelas</b>.
          </p>
          <p>
            O Protheus&trade; como ERP te fornece dados das mais diversas formas
            e tipos te posssibilitando possibilidades muito além de qualquer
            expectativa e com possibilidade de customizações.
          </p>

          <h4>Um Avião que Não Voa</h4>
          <p class="text-justify">
            Com o PowerBI&trade; você tem um avião nas mãos, mas deixá-lo com
            uma pessoa não qualificada é o mesmo que comprar um avião e
            entregá-lo a uma pessoa que não sabe voar.
          </p>
          <p>
            Atribuição de responsabilidade de dashboards e informações críticas
            é uma tarefa qua deve ser atribuída a uma empresa qualificada, aqui
            fornecemos o apoio necessário com analistas altamente qualificados.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- #about -->

  <section id="services" class="section-bg">
    <div class="container">
      <header class="section-header">
        <h3>Serviços</h3>
        <p>
          Prestamos serviços especializados em consultoria e desenvolvimento,
          temos analistas com mais de dez anos de experiência, com qualificação
          técnica e sempre atualizados em relação às novidades do mercado.
          Podemos apoiá-los com serviços de análise de dados fornecer soluções
          de auxilílio às tomadas de decisões estratégicas.
        </p>
      </header>

      <div class="row">
        <div
          class="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon">
              <i class="fab fa-git-square" style="color: #ff689b"></i>
            </div>
            <h4 class="title">
              <a href="">Versionamento de Código</a>
            </h4>
            <p class="description text-justify">
              Ainda não sabe o que é GIT? Nós trabalhamos com essa ferramenta de
              mercado gratuita e que nos dá segurança e rastreabilidade de todas
              as alterações realizadas em todas as customizações do seu projeto.
              Nós podemos configurar essa ferramenta e capacitar sua equipe para
              a correta utilização.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-5 wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon">
              <i class="fa fa-cogs" style="color: #e9bf06"></i>
            </div>
            <h4 class="title"><a href="">Automatização de Aplicações</a></h4>
            <p class="description text-justify">
              Foi desenvolvido pela nossa equipe uma ferramenta para aplicação
              de versões do Protheus&trade;. Você não precisa mais pedir para os
              usuários saírem do sistema para aplicação de um patch ou fonte.
              Nossa ferramenta além de simplificar o processo de aplicação
              garante que somente fontes no GIT serão aplicados no seu ambiente.
            </p>
          </div>
        </div>

        <div
          class="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
          data-wow-delay="0.1s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon">
              <i class="fa fa-users-cog" style="color: #3fcdc7"></i>
            </div>
            <h4 class="title"><a href="">Automatização de Testes</a></h4>
            <p class="description text-justify">
              Você ainda não sabia? A TOTVS&trade; disponibilizou desde a versão
              12.1.25 do Protheus&trade; o TIR (TOTVS&trade; Interface Robot),
              com um desenvolvimento simples e rápido é possível automatizar os
              testes e garantir que o seu sistema não voltará a ter problemas
              que já foram resolvidos.
            </p>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-5 wow bounceInUp"
          data-wow-delay="0.1s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon">
              <i class="fa fa-code" style="color: #41cf2e"></i>
            </div>
            <h4 class="title">
              <a href="">Implantação e Evolução</a>
            </h4>
            <p class="description text-justify">
              Os especialistas de nossa equipe são amplamente capacitados de
              forma que podemos fazer desde a revisão do seu processo até o
              levantamento de requisitos. Uma equipe bm sucedida trará sucesso
              para o seu negócio com tecnologia e inovação.
            </p>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
          data-wow-delay="0.2s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon">
              <i class="far fa-handshake" style="color: #d6ff22"></i>
            </div>
            <h4 class="title"><a href="">Integrações</a></h4>
            <p class="description text-justify">
              Torne o seu ERP um sistema conectado com suas ferramentas. O
              Protheus&trade; possui diversos mecanismos para integração com
              outros sistemas. Faça toda a sua massa de dados virar informação
              extraindo e aproveitando o máximo possível de cada sistema que
              você tem.
            </p>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-5 wow bounceInUp"
          data-wow-delay="0.2s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon">
              <i class="fa fa-diagnoses" style="color: #ff689b"></i>
            </div>
            <h4 class="title"><a href="">Consultoria</a></h4>
            <p class="description text-justify">
              Trabalhamos com consultoria de processos ou de desenvolvimentos.
              Deixe de trabalhar com processos mal definidos e aplicações que
              não se encaixam no seu dia a dia, podemos lhe fornecer consultores
              que buscarão a melhor solução para os seus problemas.
            </p>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-5 offset-lg-1 wow bounceInUp"
          data-wow-delay="0.2s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon">
              <i class="fa fa-chart-line" style="color: #41cf2e"></i>
            </div>
            <h4 class="title"><a href="">Atualização de Versão</a></h4>
            <p class="description text-justify">
              Nós podemos efetuar a atualização do seu sistema. Hoje trabalhamos
              com nossos clientes nas versões ativas com o dicionário no banco
              de dados. Aproveite o que o Protheus&trade; pode te oferecer, as
              atualizações estão mais estáveis e o dicionário no banco de dados
              oferece mais segurança, simplificação de backup e agilidade na
              utilização do sistema.
            </p>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-5 wow bounceInUp"
          data-wow-delay="0.2s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon">
              <i class="fa fa-book" style="color: #4680ff"></i>
            </div>
            <h4 class="title"><a href="">Capacitações</a></h4>
            <p class="description text-justify">
              Podemos capacitar seus colaboradores para que eles consigam
              extrair o máximo possível do seu ERP ou BI, com treinamentos
              direcionados para o negócio ou para desenvolvimento. Torne sua
              equipe capacitada para atender seus clientes internos e externos
              com competência e agilidade.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--
    <section id="why-us" class="wow fadeIn">
      <div class="container">
        <header class="section-header">
          <h3>Why choose us?</h3>
          <p>
            Laudem latine persequeris id sed, ex fabulas delectus quo. No vel
            partiendo abhorreant vituperatoribus.
          </p>
        </header>
  
        <div class="row row-eq-height justify-content-center">
          <div class="col-lg-4 mb-4">
            <div class="card wow bounceInUp">
              <i class="fa fa-diamond"></i>
              <div class="card-body">
                <h5 class="card-title">Corporis dolorem</h5>
                <p class="card-text">
                  Deleniti optio et nisi dolorem debitis. Aliquam nobis est
                  temporibus sunt ab inventore officiis aut voluptatibus.
                </p>
                <a href="#" class="readmore">Read more </a>
              </div>
            </div>
          </div>
  
          <div class="col-lg-4 mb-4">
            <div class="card wow bounceInUp">
              <i class="fa fa-language"></i>
              <div class="card-body">
                <h5 class="card-title">Voluptates dolores</h5>
                <p class="card-text">
                  Voluptates nihil et quis omnis et eaque omnis sint aut. Ducimus
                  dolorum aspernatur.
                </p>
                <a href="#" class="readmore">Read more </a>
              </div>
            </div>
          </div>
  
          <div class="col-lg-4 mb-4">
            <div class="card wow bounceInUp">
              <i class="fa fa-object-group"></i>
              <div class="card-body">
                <h5 class="card-title">Eum ut aspernatur</h5>
                <p class="card-text">
                  Autem quod nesciunt eos ea aut amet laboriosam ab. Eos quis
                  porro in non nemo ex.
                </p>
                <a href="#" class="readmore">Read more </a>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row counters">
          <div class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">274</span>
            <p>Clients</p>
          </div>
  
          <div class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">421</span>
            <p>Projects</p>
          </div>
  
          <div class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">1,364</span>
            <p>Hours Of Support</p>
          </div>
  
          <div class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up">18</span>
            <p>Hard Workers</p>
          </div>
        </div>
      </div>
    </section>
  
    <section id="testimonials" class="section-bg">
      <div class="container">
        <header class="section-header">
          <h3>Testimonials</h3>
        </header>
  
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="owl-carousel testimonials-carousel wow fadeInUp">
              <div class="testimonial-item">
                <img
                  src="../../assets/images/testimonial-1.jpg"
                  class="testimonial-img"
                  alt=""
                />
                <h3>Saul Goodman</h3>
                <h4>Ceo &amp; Founder</h4>
                <p>
                  Proin iaculis purus consequat sem cure digni ssim donec
                  porttitora entum suscipit rhoncus. Accusantium quam, ultricies
                  eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                </p>
              </div>
  
              <div class="testimonial-item">
                <img
                  src="../../assets/images/testimonial-2.jpg"
                  class="testimonial-img"
                  alt=""
                />
                <h3>Sara Wilsson</h3>
                <h4>Designer</h4>
                <p>
                  Export tempor illum tamen malis malis eram quae irure esse
                  labore quem cillum quid cillum eram malis quorum velit fore eram
                  velit sunt aliqua noster fugiat irure amet legam anim culpa.
                </p>
              </div>
  
              <div class="testimonial-item">
                <img
                  src="../../assets/images/testimonial-3.jpg"
                  class="testimonial-img"
                  alt=""
                />
                <h3>Jena Karlis</h3>
                <h4>Store Owner</h4>
                <p>
                  Enim nisi quem export duis labore cillum quae magna enim sint
                  quorum nulla quem veniam duis minim tempor labore quem eram duis
                  noster aute amet eram fore quis sint minim.
                </p>
              </div>
  
              <div class="testimonial-item">
                <img
                  src="../../assets/images/testimonial-4.jpg"
                  class="testimonial-img"
                  alt=""
                />
                <h3>Matt Brandon</h3>
                <h4>Freelancer</h4>
                <p>
                  Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                  multos export minim fugiat minim velit minim dolor enim duis
                  veniam ipsum anim magna sunt elit fore quem dolore labore illum
                  veniam.
                </p>
              </div>
  
              <div class="testimonial-item">
                <img
                  src="../../assets/images/testimonial-5.jpg"
                  class="testimonial-img"
                  alt=""
                />
                <h3>John Larson</h3>
                <h4>Entrepreneur</h4>
                <p>
                  Quis quorum aliqua sint quem legam fore sunt eram irure aliqua
                  veniam tempor noster veniam enim culpa labore duis sunt culpa
                  nulla illum cillum fugiat legam esse veniam culpa fore nisi
                  cillum quid.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  

  <section id="team">
    <div class="container">
      <div class="section-header">
        <h3>Team</h3>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque
        </p>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-6 wow fadeInUp">
          <div class="member">
            <img
              src="../../assets/images/team-1.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="member-info">
              <div class="member-info-content">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
                <div class="social">
                  <a href=""><i class="fa fa-twitter"></i></a>
                  <a href=""><i class="fa fa-facebook"></i></a>
                  <a href=""><i class="fa fa-google-plus"></i></a>
                  <a href=""><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="member">
            <img
              src="../../assets/images/team-2.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="member-info">
              <div class="member-info-content">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
                <div class="social">
                  <a href=""><i class="fa fa-twitter"></i></a>
                  <a href=""><i class="fa fa-facebook"></i></a>
                  <a href=""><i class="fa fa-google-plus"></i></a>
                  <a href=""><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
          <div class="member">
            <img
              src="../../assets/images/team-3.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="member-info">
              <div class="member-info-content">
                <h4>William Anderson</h4>
                <span>CTO</span>
                <div class="social">
                  <a href=""><i class="fa fa-twitter"></i></a>
                  <a href=""><i class="fa fa-facebook"></i></a>
                  <a href=""><i class="fa fa-google-plus"></i></a>
                  <a href=""><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="member">
            <img
              src="../../assets/images/team-4.jpg"
              class="img-fluid"
              alt=""
            />
            <div class="member-info">
              <div class="member-info-content">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
                <div class="social">
                  <a href=""><i class="fa fa-twitter"></i></a>
                  <a href=""><i class="fa fa-facebook"></i></a>
                  <a href=""><i class="fa fa-google-plus"></i></a>
                  <a href=""><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="clients" class="section-bg">
    <div class="container">
      <div class="section-header">
        <h3>Our CLients</h3>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque dere santome nida.
        </p>
      </div>

      <div class="row no-gutters clients-wrap clearfix wow fadeInUp">
        <div class="col-lg-3 col-md-4 col-xs-6">
          <div class="client-logo">
            <img
              src="../../assets/images/clients/client-1.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-xs-6">
          <div class="client-logo">
            <img
              src="../../assets/images/clients/client-2.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-xs-6">
          <div class="client-logo">
            <img
              src="../../assets/images/clients/client-3.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-xs-6">
          <div class="client-logo">
            <img
              src="../../assets/images/clients/client-4.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-xs-6">
          <div class="client-logo">
            <img
              src="../../assets/images/clients/client-5.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-xs-6">
          <div class="client-logo">
            <img
              src="../../assets/images/clients/client-6.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-xs-6">
          <div class="client-logo">
            <img
              src="../../assets/images/clients/client-7.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-xs-6">
          <div class="client-logo">
            <img
              src="../../assets/images/clients/client-8.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
-->

  <section id="contact">
    <div class="container-fluid">
      <div class="section-header">
        <h3>Entre em contato</h3>
      </div>
      <div class="row wow fadeInUp">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-4 info">
              <i class="far fa-envelope"></i>
              <a href="mailto:contato@bscode.com.br"
                >contato&#64;bscode.com.br</a
              >
            </div>
            <div class="col-lg-4 info">
              <i class="fas fa-phone"></i>
              <a href="tel:+5561991102824">(61) 9 9110-2824</a>
            </div>
            <div class="col-lg-4 info">
              <i class="fab fa-whatsapp"></i>
              <a href="https://wa.me/5561993192965" target="_blank"
                >(61) 9 9319-2965</a
              >
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="section-header">
        <h3>Ligamos pra você</h3>
      </div>
      <div class="row wow fadeInUp">
        <div class="col"></div>
        <div class="col" style="max-width: 800px; min-width: 500px">
          <h5>Nos envie uma mensagem que entraremos em contato</h5>
          <div class="form">
            <div *ngIf="sent" class="col-lg-12">
              <br />
              <div class="alert alert-warning alert-dismissible" role="alert">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                Sua mensagem foi enviada, lhe responderemos o quanto antes.
                Obrigado!
              </div>
              <br />
            </div>

            <p-progressSpinner
              *ngIf="loading"
              [style]="{ width: '50px', height: '50px' }"
              strokeWidth="8"
              fill="#EEEEEE"
              animationDuration=".5s"
            ></p-progressSpinner>

            <div *ngIf="!loading">
              <div id="errormessage"></div>
              <form [formGroup]="form" role="form" class="contactForm">
                <div class="form-row col-lg-12">
                  <div class="m-2 form-group col-lg-4 col-md-4">
                    <input
                      formControlName="nome"
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      placeholder="Seu Nome"
                      data-rule="minlen:4"
                      data-msg="Seu nome deve ter mais de 4 caracteres"
                    />
                    <div class="validation"></div>
                  </div>
                  <div class="m-2 form-group col-lg-4 col-md-4">
                    <input
                      formControlName="email"
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Seu E-mail"
                      data-rule="email"
                      data-msg="Verifique seu e-mail ele é inválido"
                    />
                    <div class="validation"></div>
                  </div>
                  <div class="m-2 form-group col-lg-4 col-md-4">
                    <p-inputMask
                      formControlName="fone"
                      type="tel"
                      styleClass="form-control"
                      name="fone"
                      id="fone"
                      placeholder="Seu Telefone"
                      data-rule="fone"
                      [unmask]="true"
                      [mask]="mascaraFone"
                      (onBlur)="getMask()"
                      data-msg="Verifique seu telefone ele é inválido"
                    >
                    </p-inputMask>
                    <div class="validation"></div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="m-2 form-group">
                    <input
                      formControlName="assunto"
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Assunto"
                      data-rule="minlen:4"
                      data-msg="O assunto deve ter mais de 8 caracteres"
                    />
                    <div class="validation"></div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="m-2 form-group">
                    <textarea
                      formControlName="mensagem"
                      class="form-control"
                      name="message"
                      rows="5"
                      data-rule="required"
                      data-msg="Digite sua mensagem"
                      placeholder="Mensagem"
                    ></textarea>
                    <div class="validation"></div>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    [disabled]="form.invalid"
                    class="btn btn-success"
                    (click)="onSend()"
                    title="Enviar mensagem"
                  >
                    Enviar Mensagem
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </section>
</main>

<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>

<footer id="footer">
  <div class="container">
    <div class="copyright">
      <p>
        <span>
          Microsiga®, Protheus® e TOTVS® são produtos e marcas registradas e de
          propriedade da TOTVS® e PowerBI® da Microsoft®.
        </span>
        <br />
        <span>
          A BSCODE é uma empresa de consultoria independente e não tem nenhum
          vínculo direto ou indireto com a TOTVS®, Microsoft, qualquer uma de
          suas franquias ou qualquer um de seus representantes e licenciados.
        </span>
      </p>
    </div>
  </div>
</footer>
<a class="link-whatsapp" href="https://wa.me/5561993192965" target="_blank">
  <i style="margin-top: 14px; font-size: 2rem" class="pi pi-whatsapp"></i>
</a>

<script src="assets/vendor/aos/aos.js"></script>
