<p-toast></p-toast>
<section id="intro" class="clearfix">
  <div *ngIf="!profile" id="preloader"></div>
  <div
    class="container"
    *ngIf="profile && !profile.tecnico && !profile.cliente"
  >
    <div class="card">
      <div class="card-header">
        <h1 id="tableLabel">Acesso não configurado</h1>
      </div>
      {{ profile | json }}
      <div class="card-body">
        Para vizualizar seus dados de cliente ou analista solicite a um analista
        da BsCode a configuração do seu acesso!
      </div>
    </div>
  </div>
  <div
    class="container"
    *ngIf="profile && (profile.tecnico || profile.cliente)"
  >
    <div class="card">
      <div class="card-header">
        <h1 id="tableLabel">Atendimentos realizados</h1>
      </div>
      <div class="card-body">
        <p-progressSpinner
          *ngIf="loading"
          [style]="{
            width: '50px',
            height: '50px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }"
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration=".5s"
        ></p-progressSpinner>
        <div *ngIf="!loading">
          <div class="field grid flex">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <p-floatLabel variant="in">
                <p-select
                  *ngIf="clientes"
                  inputId="cliente"
                  [options]="clientes"
                  [(ngModel)]="selectedCliente"
                  (onChange)="onChangeCliente()"
                  optionLabel="nome"
                  optionValue="id"
                  [filter]="true"
                ></p-select>
                <label for="cliente">Cliente</label>
              </p-floatLabel>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
              <p-floatLabel variant="in">
                <p-select
                  *ngIf="
                    periodos &&
                    selectedCliente !== '*digit*' &&
                    selectedCliente !== '*receb*'
                  "
                  inputId="periodo"
                  [options]="periodos"
                  [(ngModel)]="selectedPeriodo"
                  (onChange)="onChangeperiodo()"
                  [filter]="true"
                  optionLabel="descricao"
                  optionValue="id"
                ></p-select>
                <label for="periodo">Período</label>
              </p-floatLabel>
              <p-datePicker
                selectionMode="range"
                [readonlyInput]="true"
                [(ngModel)]="rangeDates"
                (onChange)="onChangeperiodo()"
                (onSelect)="onChangeperiodo()"
                *ngIf="
                  selectedCliente === '*digit*' || selectedCliente === '*receb*'
                "
              ></p-datePicker>
            </div>
            <div
              class="col-sm-12 col-md-6 col-lg-2"
              *ngIf="
                selectedCliente === '*pgto*' ||
                selectedCliente === '*emis*' ||
                selectedCliente === '*digit*' ||
                selectedCliente === '*receb*'
              "
            >
              <h3>Filial:</h3>
            </div>
            <div
              class="col-sm-12 col-md-6 col-lg-4"
              *ngIf="
                selectedCliente === '*pgto*' ||
                selectedCliente === '*emis*' ||
                selectedCliente === '*digit*' ||
                selectedCliente === '*receb*'
              "
            >
              <p-floatLabel variant="in">
                <p-select
                  *ngIf="filiais"
                  inputId="filial"
                  [options]="filiais"
                  [(ngModel)]="selectedFilial"
                  (onChange)="onChangeFilial()"
                  [filter]="true"
                  [autoDisplayFirst]="false"
                  [showClear]="true"
                  optionValue="id"
                  optionLabel="Nome"
                ></p-select>
                <label for="filial">Filial</label>
              </p-floatLabel>
            </div>
          </div>
        </div>
        <div
          class="p-col p-col-align-end"
          *ngIf="
            !loading &&
            selectedCliente !== '*pgto*' &&
            selectedCliente !== '*emis*' &&
            selectedCliente !== '*digit*' &&
            selectedCliente !== '*receb*'
          "
        >
          <div
            *ngIf="
              profile.perfil == 'Administrador' &&
              selectedPeriodoObj &&
              selectedPeriodoObj.data_pagamento
            "
          >
            <label
              >Previsão de Pagamento:
              {{
                selectedPeriodoObj.data_pagamento | date : 'dd/MM/yyyy'
              }}</label
            >
          </div>
          <div
            *ngIf="
              profile.perfil == 'Administrador' &&
              selectedPeriodoObj &&
              selectedPeriodoObj.data_emissao_nota
            "
          >
            <label
              >Emissão Nota:
              {{
                selectedPeriodoObj.data_emissao_nota | date : 'dd/MM/yyyy'
              }}</label
            >
          </div>
        </div>
        <p-table
          [value]="analistas"
          *ngIf="!loading"
          dataKey="id"
          [expandedRowKeys]="expandedRows"
          styleClass="p-datatable-striped p-datatable-sm"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem"></th>
              <th>Nome</th>
              <th class="align-end">Duração(H)</th>
              <th
                class="align-end"
                *ngIf="
                  profile.perfil == 'Administrador' ||
                  profile.perfil == 'Cliente'
                "
              >
                Valor Bruto
              </th>
              <th class="align-end" *ngIf="profile.perfil == 'Administrador'">
                Imposto
              </th>
              <th class="align-end" *ngIf="profile.perfil == 'Administrador'">
                Valor Líquido
              </th>
              <th class="align-end" *ngIf="profile.perfil == 'Administrador'">
                Distribuição
              </th>
              <th
                class="align-end"
                *ngIf="
                  profile.perfil == 'Administrador' ||
                  profile.perfil == 'Analista'
                "
              >
                A Receber
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-analista let-expanded="expanded">
            <tr
              *ngIf="
                (profile.perfil == 'Cliente'
                  ? analista.total_bruto
                  : analista.total_receber) !== 0
              "
            >
              <td
                *ngIf="
                  selectedCliente !== '*pgto*' &&
                  selectedCliente !== '*emis*' &&
                  selectedCliente !== '*digit*' &&
                  selectedCliente !== '*receb*'
                "
              ></td>
              <td
                *ngIf="
                  selectedCliente === '*pgto*' ||
                  selectedCliente === '*emis*' ||
                  selectedCliente === '*digit*' ||
                  selectedCliente === '*receb*'
                "
              >
                <p-button
                  type="button"
                  pRipple
                  [pRowToggler]="analista"
                  [text]="true"
                  [rounded]="true"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                ></p-button>
              </td>
              <td>
                <span class="p-column-title">Nome: </span
                ><b>{{ analista.nome }}</b>
              </td>
              <td class="align-end">
                <span class="p-column-title">Duração(H): </span
                ><b>{{ analista.total_tempo | number : '1.2-2' }}</b>
              </td>
              <td
                class="align-end"
                *ngIf="
                  profile.perfil == 'Administrador' ||
                  profile.perfil == 'Cliente'
                "
              >
                <span class="p-column-title">Valor Bruto: </span
                ><b>{{ analista.total_bruto | currency : 'BRL' }}</b>
              </td>
              <td class="align-end" *ngIf="profile.perfil == 'Administrador'">
                <span class="p-column-title">Imposto: </span
                ><b>{{ analista.total_imposto | currency : 'BRL' }}</b>
              </td>
              <td class="align-end" *ngIf="profile.perfil == 'Administrador'">
                <span class="p-column-title">Valor Líquido: </span
                ><b>{{
                  analista.total_bruto - analista.total_imposto
                    | currency : 'BRL'
                }}</b>
              </td>
              <td class="align-end" *ngIf="profile.perfil == 'Administrador'">
                <span class="p-column-title">Distribuição: </span
                ><b>{{ analista.total_distribuicao | currency : 'BRL' }}</b>
              </td>
              <td
                class="align-end"
                *ngIf="
                  profile.perfil == 'Administrador' ||
                  profile.perfil == 'Analista'
                "
              >
                <span class="p-column-title">A Receber: </span
                ><b>{{ analista.total_receber | currency : 'BRL' }}</b>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="rowexpansion" let-analista>
            <tr>
              <td [attr.colspan]="profile.perfil == 'Administrador' ? 8 : 5">
                <p-table
                  [value]="analista['atendimentos']"
                  dataKey="xId"
                  [expandedRowKeys]="
                    expandedKeys(expandedRowsSubItens, analista)
                  "
                  class="expandable-table-periodos"
                >
                  <ng-template
                    pTemplate="body"
                    let-cliente
                    let-expandedCliente="expanded"
                  >
                    <tr>
                      <th style="width: 3rem">
                        <p-button
                          type="button"
                          pRipple
                          [pRowToggler]="cliente"
                          [text]="true"
                          [rounded]="true"
                          [icon]="
                            expandedCliente
                              ? 'pi pi-chevron-down'
                              : 'pi pi-chevron-right'
                          "
                        ></p-button>
                      </th>
                      <td>
                        <span class="p-column-title">Nome: </span
                        >{{ cliente.nome_cliente }}
                      </td>
                      <td class="align-end">
                        <span class="p-column-title">Duração(H): </span
                        >{{ cliente.total_tempo | number : '1.2-2' }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="
                          profile.perfil == 'Administrador' ||
                          profile.perfil == 'Cliente'
                        "
                      >
                        <span class="p-column-title">Valor Bruto: </span
                        >{{ cliente.total_bruto | currency : 'BRL' }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="profile.perfil == 'Administrador'"
                      >
                        <span class="p-column-title">Imposto: </span
                        >{{ cliente.total_imposto | currency : 'BRL' }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="profile.perfil == 'Administrador'"
                      >
                        <span class="p-column-title">Valor Líquido: </span
                        >{{
                          cliente.total_bruto - cliente.total_imposto
                            | currency : 'BRL'
                        }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="profile.perfil == 'Administrador'"
                      >
                        <span class="p-column-title">Distribuição: </span
                        >{{ cliente.total_distribuicao | currency : 'BRL' }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="
                          profile.perfil == 'Administrador' ||
                          profile.perfil == 'Analista'
                        "
                      >
                        <span class="p-column-title">A Receber: </span
                        >{{ cliente.total_receber | currency : 'BRL' }}
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-cliente>
                    <tr *ngFor="let periodo of cliente['periodos']">
                      <th style="width: 3rem"></th>
                      <td>
                        <span class="p-column-title">Nome: </span
                        >{{ periodo.descricao_periodo }}
                      </td>
                      <td class="align-end">
                        <span class="p-column-title">Duração(H): </span
                        >{{ periodo.total_tempo | number : '1.2-2' }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="
                          profile.perfil == 'Administrador' ||
                          profile.perfil == 'Cliente'
                        "
                      >
                        <span class="p-column-title">Valor Bruto: </span
                        >{{ periodo.total_bruto | currency : 'BRL' }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="profile.perfil == 'Administrador'"
                      >
                        <span class="p-column-title">Imposto: </span
                        >{{ periodo.total_imposto | currency : 'BRL' }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="profile.perfil == 'Administrador'"
                      >
                        <span class="p-column-title">Valor Líquido: </span
                        >{{
                          periodo.total_bruto - periodo.total_imposto
                            | currency : 'BRL'
                        }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="profile.perfil == 'Administrador'"
                      >
                        <span class="p-column-title">Distribuição: </span
                        >{{ periodo.total_distribuicao | currency : 'BRL' }}
                      </td>
                      <td
                        class="align-end"
                        *ngIf="
                          profile.perfil == 'Administrador' ||
                          profile.perfil == 'Analista'
                        "
                      >
                        <span class="p-column-title">A Receber: </span
                        >{{ periodo.total_receber | currency : 'BRL' }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </td>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="footer"
            *ngIf="
              profile.perfil == 'Administrador' || profile.perfil == 'Cliente'
            "
          >
            <tr>
              <th style="width: 3rem"></th>
              <td>Totais</td>
              <td class="align-end">
                {{ total_tempo | number : '1.2-2' }}
              </td>
              <td
                class="align-end"
                *ngIf="
                  profile.perfil == 'Administrador' ||
                  profile.perfil == 'Cliente'
                "
              >
                {{ total_bruto | currency : 'BRL' }}
              </td>
              <td class="align-end" *ngIf="profile.perfil == 'Administrador'">
                {{ total_imposto | currency : 'BRL' }}
              </td>
              <td class="align-end" *ngIf="profile.perfil == 'Administrador'">
                {{ total_bruto - total_imposto | currency : 'BRL' }}
              </td>
              <td class="align-end" *ngIf="profile.perfil == 'Administrador'">
                {{ total_distribuicao | currency : 'BRL' }}
              </td>
              <td class="align-end" *ngIf="profile.perfil == 'Administrador'">
                {{ total_bruto - total_imposto | currency : 'BRL' }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <p-table
        #tblAtendimentos
        id="atendimentosTable"
        [columns]="cols"
        [value]="atendimentosOriginal"
        selectionMode="single"
        [(selection)]="selectedAtendimento"
        (onRowSelect)="onRowSelect()"
        [paginator]="true"
        [rows]="15"
        *ngIf="!loading"
        styleClass="p-datatable-striped p-datatable-sm"
        stateStorage="session"
        [stateKey]="
          'tblAtendimentos_' + selectedCliente + '_' + selectedPeriodo
        "
        (onFilter)="onFilter($event)"
      >
        <ng-template pTemplate="caption">
          <div class="flex">
            Lista de atendimentos (
            {{ selectedPeriodoObj ? selectedPeriodoObj.status : '' }} )
            <i
              style="color: whitesmoke"
              class=" pi {{
                selectedPeriodoObj ? selectedPeriodoObj.icon : ''
              }}"
            ></i>
            <div class="ml-auto">
              <button
                pButton
                label="Limpar"
                class="p-button-outlined mr-2"
                icon="pi pi-filter-slash"
                (click)="tblAtendimentos.clear()"
              ></button>
              <span class="p-input-icon-left ml-auto mr-2">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="Filtrar(tblAtendimentos.filterGlobal, $event)"
                  placeholder="Filtrar"
                />
              </span>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                (click)="exportExcel()"
                class="p-button-success mr-2"
                pTooltip="XLS"
                tooltipPosition="bottom"
                *ngIf="profile.perfil == 'Administrador'"
              ></button>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-pdf"
                (click)="exportPdf()"
                class="p-button-warning mr-2"
                pTooltip="PDF"
                tooltipPosition="bottom"
                *ngIf="profile.perfil == 'Administrador'"
              ></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              [class]="col.class ? col.class : ''"
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
            >
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
              <p-columnFilter
                *ngIf="
                  col.field !== 'nome_cliente' && col.field !== 'nome_tecnico'
                "
                type="text"
                [field]="col.field"
                display="menu"
              ></p-columnFilter>
              <p-columnFilter
                [field]="col.field"
                *ngIf="col.field === 'nome_tecnico'"
                matchMode="in"
                display="menu"
                [showMatchModes]="false"
                [showOperator]="false"
                [showAddButton]="false"
              >
                <ng-template
                  pTemplate="filter"
                  let-value
                  let-filter="filterCallback"
                >
                  <p-multiSelect
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="30"
                    [options]="nomesAnalistas"
                    [ngModel]="value"
                    (onChange)="filter($event.value)"
                    placeholder="Todos"
                    appendTo="body"
                    [maxSelectedLabels]="1"
                    [selectedItemsLabel]="'{0} itens'"
                  >
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <p-columnFilter
                [field]="col.field"
                *ngIf="col.field === 'nome_cliente'"
                matchMode="in"
                display="menu"
                [showMatchModes]="false"
                [showOperator]="false"
                [showAddButton]="false"
              >
                <ng-template
                  pTemplate="filter"
                  let-value
                  let-filter="filterCallback"
                >
                  <p-multiSelect
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="30"
                    [options]="nomesClientes"
                    [ngModel]="value"
                    (onChange)="filter($event.value)"
                    placeholder="Todos"
                    appendTo="body"
                    [maxSelectedLabels]="1"
                    [selectedItemsLabel]="'{0} itens'"
                  >
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td
              *ngFor="let col of columns"
              [class]="
                (rowData.conflicted ? 'p-confirm-dialog-reject ' : '') +
                (col.class ? col.class : '')
              "
            >
              <span class="p-column-title">{{ col.header }}: </span
              >{{
                col.type
                  ? col.type.transform(
                      rowData[col.field],
                      col.arg1,
                      col.arg2,
                      col.arg3
                    )
                  : rowData[col.field]
              }}
            </td>
          </tr>
        </ng-template>

        <ng-template #summary>
          <button
            type="button"
            pButton
            *ngIf="
              profile.perfil != 'Cliente' &&
              periodoAberto() &&
              selectedCliente !== '*pgto*' &&
              selectedCliente !== '*emis*' &&
              selectedCliente !== '*digit*' &&
              selectedCliente !== '*receb*'
            "
            icon="pi pi-plus"
            (click)="showDialogToAdd()"
            label="Incluir"
            class="ml-2 mb-2"
          ></button>
        </ng-template>
      </p-table>
      <app-atendimento-detail
        *ngIf="displayDialog"
        [atendimentoOriginal]="selectedAtendimento"
        [selectedCliente]="getCliente(selectedAtendimento)"
        [selectedPeriodo]="getperiodo(selectedAtendimento)"
        [readonly]="
          !periodoAberto(selectedAtendimento) || profile.perfil == 'Cliente'
        "
        [profile]="profile"
        (loadBrowser)="loadAtendimentos($event)"
        [allperiodos]="allperiodos"
        [allClientes]="clientes"
      >
      </app-atendimento-detail>
    </div>
  </div>
</section>
