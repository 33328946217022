import { NgModule } from '@angular/core';

import { AuthRoutingModule } from './auth-routing.module';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { AvatarModule } from 'primeng/avatar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FloatLabel } from 'primeng/floatlabel';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';

@NgModule({
  declarations: [],
  imports: [
    AuthRoutingModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    AvatarModule,
    FloatLabel,
    InputMaskModule,
    PasswordModule,
    ProgressSpinnerModule,
  ],
})
export class AuthModule {}
