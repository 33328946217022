<header id="header" class="fixed-top">
  <div class="container" style="display: flex">
    <div class="logo flex-grow-1">
      <div routerLink fragment="intro"></div>
      <a href="#intro" class="scrollto"
        ><img src="../../assets/images/logo.png" alt="" class="img-fluid"
      /></a>
    </div>
    <button
      type="button"
      (click)="onClickMenu()"
      class="mobile-nav-toggle d-lg-none"
    >
      <i class="fa fa-bars"></i>
    </button>
    <nav class="main-nav float-right d-none d-lg-block">
      <ul>
        <li
          class="active"
          [routerLinkActive]="['link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div routerLink fragment="intro"><a href="#intro">Início</a></div>
        </li>

        <li>
          <div routerLink fragment="about"><a href="#about">Quem Somos</a></div>
        </li>
        <li>
          <div routerLink fragment="services">
            <a href="#services">Serviços</a>
          </div>
        </li>
        <li>
          <a href="http://webmail.bscode.com.br">E-mail</a>
        </li>
        <li>
          <div routerLink fragment="about">
            <a href="#contact">Contato</a>
          </div>
        </li>
        <li [class]="isAuthenticated ? 'drop-down' : ''">
          <a [routerLink]="!isAuthenticated ? ['/atendimento'] : undefined"
            >Área Restrita</a
          >
          <ul class="navbar-nav flex-grow" *ngIf="isAuthenticated">
            <li class="nav-item" [routerLinkActive]="['link-active']">
              <a class="nav-link text-dark" [routerLink]="['/atendimento']"
                >Atendimentos</a
              >
            </li>
            <li
              class="nav-item"
              *ngIf="profile && profile.perfil == 'Administrador'"
              [routerLinkActive]="['link-active']"
            >
              <a class="nav-link text-dark" [routerLink]="['/cliente']"
                >Clientes</a
              >
            </li>
            <li
              class="nav-item"
              *ngIf="profile && profile.perfil == 'Administrador'"
            >
              <a class="nav-link text-dark" [routerLink]="['/tecnico']"
                >Técnicos</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark" (click)="logout()" title="Logout">
                Logout</a
              >
            </li>
          </ul>
        </li>

        <li class="nav-item" *ngIf="isAuthenticated">
          <a
            class="nav-link text-dark"
            [routerLink]="['/authentication/profile']"
            title="Manage"
            >Olá {{ userName }}</a
          >
        </li>
      </ul>
    </nav>
    <!-- .main-nav -->
    <div class="mobile-nav-overly"></div>
  </div>
</header>
