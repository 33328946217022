import { Component } from '@angular/core';

@Component({
  selector: 'app-criador-assinatura',
  templateUrl: './criador-assinatura.html',
  standalone: false,
})
export class CriadorAssinaturaComponent {
  name: string = '';
  cargo: string = '';
  phone: string = '';
  mail: string = '';

  codigo: string = '';

  baixa() {
    const modeloAssinatura = document.getElementById('modelo-assinatura');
    if (
      modeloAssinatura &&
      modeloAssinatura.firstChild instanceof HTMLElement
    ) {
      this.codigo = modeloAssinatura.firstChild.outerHTML;
    }

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:attachment/text,' + encodeURI(this.codigo);

    hiddenElement.target = '_blank';
    hiddenElement.download = 'Minha Assinatura.txt';
    hiddenElement.click();
  }
}
