<p-toast></p-toast>
<section id="intro" class="clearfix">
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h1 id="tableLabel">Técnicos</h1>
      </div>
      <div class="card-body">
        <p-toolbar styleClass="mb-4 gap-2">
          <ng-template pTemplate="left">
            <button
              pButton
              pRipple
              label="New"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              (click)="openNew()"
            ></button>
          </ng-template>
        </p-toolbar>

        <p-table
          *ngIf="showList"
          [value]="tecnicos"
          dataKey="id"
          [tableStyle]="{ 'min-width': '60rem' }"
          editMode="row"
          sortMode="multiple"
          [multiSortMeta]="multiSortMeta"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 5rem"></th>
              <th pSortableColumn="id">
                Id <p-sortIcon field="id"></p-sortIcon>
              </th>
              <th pSortableColumn="Nome">
                Nome <p-sortIcon field="Nome"></p-sortIcon>
              </th>
              <th pSortableColumn="ChavePix">
                ChavePix <p-sortIcon field="ChavePix"></p-sortIcon>
              </th>
              <th pSortableColumn="Usuario">
                Usuario <p-sortIcon field="Usuario"></p-sortIcon>
              </th>
              <th pSortableColumn="Ativo">
                Ativo <p-sortIcon field="Ativo"></p-sortIcon>
              </th>
              <th style="width: 5rem"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-tecnico
            let-expanded="expanded"
            let-editing="editing"
            let-ri="rowIndex"
          >
            <tr [pEditableRow]="tecnico">
              <td>
                <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="tecnico"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                ></button>
              </td>
              <td>
                {{ tecnico.Id }}
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="tecnico.Nome" />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ tecnico.Nome }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="text"
                      [(ngModel)]="tecnico.ChavePix"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ tecnico.ChavePix }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="usuarios"
                      [(ngModel)]="tecnico.Usuario"
                      [filter]="true"
                      [autoDisplayFirst]="false"
                      [showClear]="true"
                      appendTo="body"
                      optionLabel="Email"
                      [disabled]="!tecnico || tecnico.Id === 0"
                    >
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ tecnico.Usuario ? tecnico.Usuario.Email : '' }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-selectButton
                      [options]="[
                        { value: false, label: 'Inativo' },
                        { value: true, label: 'Ativo' }
                      ]"
                      [(ngModel)]="tecnico.Ativo"
                      optionLabel="label"
                      optionValue="value"
                    ></p-selectButton>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p-tag
                      [value]="tecnico.Ativo ? 'Ativo' : 'Inativo'"
                      [severity]="tecnico.Ativo ? 'success' : 'danger'"
                    ></p-tag>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <div
                  class="flex align-items-center justify-content-center gap-2"
                >
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onRowEditInit(tecnico)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="!editing"
                    pButton
                    pRipple
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-text p-button-danger"
                    (click)="onDeleteTecnico(tecnico)"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="onRowEditSave(tecnico)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(tecnico, ri)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-tecnico>
            <tr>
              <td colspan="7">
                <div class="p-3">
                  <h5 id="tableLabel">Rateios {{ tecnico.Nome }}</h5>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-plus"
                    (click)="AddRateio(tecnico)"
                    label="Incluir"
                  ></button>
                  <p-table
                    [value]="tecnico.Rateios"
                    dataKey="id"
                    editMode="row"
                    sortMode="multiple"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th pSortableColumn="id">
                          Id <p-sortIcon field="id"></p-sortIcon>
                        </th>
                        <th pSortableColumn="cliente_id">
                          Cliente <p-sortIcon field="cliente_id"></p-sortIcon>
                        </th>
                        <th pSortableColumn="periodo_id">
                          Período <p-sortIcon field="periodo_id"></p-sortIcon>
                        </th>
                        <th pSortableColumn="tecnico_recebedor_id">
                          Recebedor
                          <p-sortIcon field="tecnico_recebedor_id"></p-sortIcon>
                        </th>
                        <th pSortableColumn="valor_hora">
                          Valor Hora
                          <p-sortIcon field="valor_hora"></p-sortIcon>
                        </th>
                        <th pSortableColumn="Porcentagem">
                          Porcentagem
                          <p-sortIcon field="Porcentagem"></p-sortIcon>
                        </th>
                        <th style="width: 5rem"></th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rateio
                      let-expanded="expanded"
                      let-editing="editing"
                      let-ri="rowIndex"
                    >
                      <tr [pEditableRow]="rateio">
                        <td>{{ rateio.Id }}</td>
                        <td>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <p-dropdown
                                [options]="clientes"
                                [(ngModel)]="rateio.cliente_id"
                                [filter]="true"
                                [autoDisplayFirst]="false"
                                [showClear]="true"
                                appendTo="body"
                                [disabled]="rateio.periodo_id"
                              >
                              </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{ Nome(clientes, rateio.cliente_id) }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <p-dropdown
                                [options]="periodos"
                                [(ngModel)]="rateio.periodo_id"
                                [filter]="true"
                                [autoDisplayFirst]="false"
                                [showClear]="true"
                                appendTo="body"
                                [disabled]="rateio.cliente_id"
                              >
                              </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{ Nome(periodos, rateio.periodo_id) }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <p-dropdown
                                [options]="tecnicos"
                                [(ngModel)]="rateio.tecnico_recebedor_id"
                                [filter]="true"
                                [autoDisplayFirst]="false"
                                [showClear]="true"
                                optionValue="id"
                                optionLabel="Nome"
                                appendTo="body"
                              >
                              </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{
                                NomeById(tecnicos, rateio.tecnico_recebedor_id)
                              }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <p-inputNumber
                                mode="decimal"
                                locale="pt-BR"
                                [minFractionDigits]="2"
                                type="text"
                                [min]="0"
                                [(ngModel)]="rateio.valor_hora"
                                [disabled]="rateio.Porcentagem > 0"
                              ></p-inputNumber>
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{ rateio.valor_hora }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <p-inputNumber
                                mode="decimal"
                                locale="pt-BR"
                                [minFractionDigits]="2"
                                type="text"
                                [min]="0"
                                [max]="100"
                                [(ngModel)]="rateio.Porcentagem"
                                [disabled]="rateio.valor_hora > 0"
                              ></p-inputNumber>
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{ rateio.Porcentagem }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <div
                            class="flex align-items-center justify-content-center gap-2"
                          >
                            <button
                              *ngIf="!editing"
                              pButton
                              pRipple
                              type="button"
                              pInitEditableRow
                              icon="pi pi-pencil"
                              (click)="onRowEditInitRateio(rateio)"
                              class="p-button-rounded p-button-text"
                            ></button>
                            <button
                              *ngIf="!editing"
                              pButton
                              pRipple
                              type="button"
                              icon="pi pi-trash"
                              (click)="onDeleteRateio(rateio)"
                              class="p-button-rounded p-button-text"
                            ></button>
                            <button
                              *ngIf="editing"
                              pButton
                              pRipple
                              type="button"
                              pSaveEditableRow
                              icon="pi pi-check"
                              (click)="onRowEditSaveRateio(rateio)"
                              class="p-button-rounded p-button-text p-button-success mr-2"
                            ></button>
                            <button
                              *ngIf="editing"
                              pButton
                              pRipple
                              type="button"
                              pCancelEditableRow
                              icon="pi pi-times"
                              (click)="
                                onRowEditCancelRateio(rateio, tecnico, ri)
                              "
                              class="p-button-rounded p-button-text p-button-danger"
                            ></button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6">Não foi possível listar os Rateios.</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</section>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
