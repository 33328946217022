import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { Mail } from '../domains/mail';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: false,
})
export class HomeComponent implements OnInit {
  public version: string = environment.version;
  form: UntypedFormGroup = null as any;

  loading = true;
  sent = false;

  mascaraFone = '(99) 9999-9999?9';

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.loading = false;
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      fone: ['', Validators.required],
      assunto: ['', Validators.required],
      mensagem: ['', Validators.required],
    });
  }

  getMask() {
    const telefone = this.form.get('fone')?.value;
    this.mascaraFone =
      telefone.length === 11 ? '(99) 9 9999-9999' : '(99) 9999-9999?9';
    setTimeout(() => {
      this.form.get('fone')?.setValue(telefone);
    }, 1);
  }

  onSend() {
    this.loading = true;

    const url = environment.apiUrl + 'api/mail/';

    const tempMail = new Mail();
    tempMail.nome = this.form.get('nome')?.value;
    tempMail.email = this.form.get('email')?.value;
    tempMail.assunto = this.form.get('assunto')?.value;
    tempMail.mensagem =
      this.form.get('mensagem')?.value +
      '<br>Telefone: ' +
      this.form.get('fone')?.value;

    this.loading = true;
    this.http.put<Mail>(url, tempMail).subscribe(
      () => {
        this.sent = true;
        this.messageService.add({
          severity: 'success',
          summary: 'Mensagem enviada com sucesso',
          detail: 'Sua operação foi realizada.',
        });

        this.form.reset();
      },
      (error) => {
        // console.log(error);

        this.messageService.add({
          severity: 'error',
          summary: 'Falha no envio do email',
          detail: 'Via MessageService',
        });
      },
      () => {
        this.loading = false;
      }
    );
  }
}
