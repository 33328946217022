<p-toast></p-toast>
<p-dialog
  header="Cadastro de Atendimento"
  [focusOnShow]="false"
  [responsive]="true"
  [(visible)]="showDialog"
  (onHide)="cancel()"
  showEffect="fade"
  [modal]="true"
  [style]="{ width: '900px', height: '1200px' }"
>
  <div class="field grid flex" *ngIf="!atendimento">
    <p class="col-12 flex">Aguarde carregando...</p>
    <p class="col-12 flex">
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    </p>
  </div>

  <div class="field grid flex" *ngIf="atendimento">
    <div class="col-12 flex">
      <div class="col">
        <p-floatLabel variant="in">
          <p-select
            inputId="cliente"
            [options]="clientes"
            [(ngModel)]="selectedCli"
            [filter]="true"
            [disabled]="readonly || profile.perfil != 'Administrador'"
            [autoDisplayFirst]="false"
            (onChange)="changeCliente()"
            optionLabel="nome"
            optionValue="id"
          ></p-select>
          <label for="cliente">Cliente</label>
        </p-floatLabel>
      </div>
      <div class="col">
        <p-floatLabel variant="in">
          <p-select
            inputId="periodo"
            [options]="periodos"
            [(ngModel)]="selectedPer"
            [filter]="true"
            [disabled]="readonly || profile.perfil != 'Administrador'"
            [autoDisplayFirst]="false"
            (onChange)="changeperiodo()"
            optionLabel="descricao"
            optionValue="id"
          ></p-select>
          <label for="periodo">Período</label>
        </p-floatLabel>
      </div>
    </div>
    <div class="col-12 flex" *ngIf="analistas">
      <div class="col">
        <p-floatLabel variant="in">
          <p-select
            inputId="analista"
            [options]="readonly ? analistas : analistasAtivos"
            [(ngModel)]="selectedAnalista"
            [filter]="true"
            [disabled]="readonly || profile.perfil != 'Administrador'"
            optionLabel="nome"
          ></p-select>
          <label for="analista">Analista</label>
        </p-floatLabel>
      </div>
    </div>
    <div class="col-12 flex">
      <div class="col">
        <p-floatLabel variant="in">
          <p-datePicker
            inputId="data_inicio"
            [(ngModel)]="atendimento.data_inicio"
            dateFormat="dd/mm/yy"
            [showTime]="true"
            hourFormat="24"
            [stepMinute]="15"
            [showSeconds]="false"
            [showButtonBar]="true"
            (onBlur)="changeDates()"
            (onSelect)="changeDates()"
            (onClose)="changeDates()"
            [required]="true"
            [disabled]="readonly"
            [minDate]="minDate"
            [maxDate]="hoje"
            appendTo="body"
          ></p-datePicker>
          <label for="data_inicio">Data Início</label>
        </p-floatLabel>
      </div>

      <div class="col">
        <p-floatLabel variant="in">
          <p-inputNumber
            inputId="duracao"
            [(ngModel)]="duration"
            suffix=" minutos"
            (onBlur)="changeDuration()"
            [showButtons]="true"
            buttonLayout="horizontal"
            spinnerMode="horizontal"
            [step]="15"
            incrementButtonIcon="pi pi-plus"
            [min]="15"
            decrementButtonIcon="pi pi-minus"
            [disabled]="readonly"
          ></p-inputNumber>
          <label for="duracao">Duração</label>
        </p-floatLabel>
      </div>

      <div class="col">
        <p-floatLabel variant="in">
          <p-datePicker
            inputId="data_fim"
            [minDate]="atendimento.data_inicio"
            [(ngModel)]="atendimento.data_fim"
            dateFormat="dd/mm/yy"
            [showTime]="true"
            hourFormat="24"
            [stepMinute]="15"
            [showSeconds]="false"
            [showButtonBar]="true"
            [required]="true"
            [disabled]="readonly"
            (onBlur)="changeDates()"
            (onSelect)="changeDates()"
            (onClose)="changeDates()"
            appendTo="body"
            [maxDate]="maxDate"
          ></p-datePicker>
          <label for="data_fim">Data Fim</label>
        </p-floatLabel>
      </div>
    </div>
    <div class="col-12 flex">
      <div class="col">
        <p-floatLabel variant="in">
          <textarea
            pTextarea
            inputId="descricao"
            [rows]="10"
            pInputTextarea
            [(ngModel)]="atendimento.descricao"
            [required]="true"
            [disabled]="readonly"
            style="resize: none; width: 100%"
            class="h-full"
          ></textarea>
          <label for="descricao">Descrição do Atendimento</label>
        </p-floatLabel>
      </div>
    </div>
  </div>
  <ng-template #footer>
    <div class="flex justify-end gap-2">
      <p-button
        *ngIf="atendimento.id && !readonly"
        type="button"
        icon="pi pi-exclamation-triangle"
        (onClick)="delete()"
        severity="danger"
        [disabled]="enviando"
        label="Excluir"
      ></p-button>
      <p-button
        type="button"
        icon="pi pi-times"
        (onClick)="cancel()"
        severity="info"
        [disabled]="enviando"
        label="Cancelar"
      ></p-button>
      <p-button
        *ngIf="!readonly"
        type="button"
        icon="pi pi-{{ atendimento.id ? 'pencil' : 'check' }}"
        (onClick)="save()"
        severity="success"
        [disabled]="enviando"
        label="{{ atendimento.id ? 'Salvar' : 'Incluir' }}"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

<p-confirmDialog></p-confirmDialog>
