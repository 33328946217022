import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNG } from 'primeng/config';
import { AuthService } from './auth/auth.service';
import { User } from './auth/user.model';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent implements OnInit {
  title = 'app';
  public version: string = environment.version;
  public isAuthenticated: boolean = false;
  public userName: string = '';
  constructor(
    private authorizeService: AuthService,
    private config: PrimeNG,
    public translate: TranslateService
  ) {
    const newUrl = window.location.href.replace(/\/\/www\./gim, '//');

    console.log(window.location.href);
    console.log(newUrl);

    // remove o www da url sempre dá pau se um usa com e outro sem
    if (newUrl !== window.location.href) {
      window.location.href = newUrl;
      console.log('redirect');
    }
  }
  public get profile(): User {
    return this.authorizeService.getUser();
  }

  ngOnInit() {
    this.getPerfil();
    this.translate.setDefaultLang('pt');
    this.translate.use('pt');
    this.translate
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  getPerfil() {
    this.isAuthenticated = this.authorizeService.getUser()?.id ? true : false;
    this.userName = this.authorizeService.getUser()?.nome;
  }
}
