<p-toast></p-toast>
<section id="intro" class="clearfix">
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h1 id="tableLabel">Clientes/periodos</h1>
      </div>
      <div class="card-body">
        <p-dataView
          #dv
          [value]="clientes"
          [paginator]="true"
          [rows]="10"
          paginatorPosition="both"
          filterBy="brand"
          [sortField]="sortField"
          [sortOrder]="sortOrder"
          *ngIf="clientes.length > 0"
        >
          <ng-template #header>
            <div class="p-helper-clearfix">
              <div class="field grid flex">
                <div class="col-sm-6 col-md-4 filter-container">
                  <div style="position: relative">
                    <input
                      type="search"
                      pInputText
                      placeholder="Buscar por nome"
                      (input)="Filtrar(dv.filter, $event)"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-4" style="text-align: right">
                  <!--<p-dataViewLayoutOptions></p-dataViewLayoutOptions>-->
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template #list let-clientes>
            <div
              class="col-sm-12"
              *ngFor="let cliente of clientes; let first = first"
            >
              <div class="car-details">
                <div>
                  <img
                    src="../../../assets/images/{{ cliente.nome }}.png"
                    width="60px"
                  />
                  <div class="field grid flex">
                    <div class="col-sm-12">
                      Nome: <b>{{ cliente.nome }}</b>
                    </div>
                    <div class="col-sm-12">
                      Ativo: <b>{{ cliente.ativo }}</b>
                    </div>
                  </div>
                </div>
                <button
                  pButton
                  type="button"
                  icon="pi pi-search"
                  (click)="selectCliente($event, cliente)"
                ></button>
              </div>
            </div>
          </ng-template>
        </p-dataView>

        <p-dialog
          header="periodos do Cliente"
          [(visible)]="displayDialog"
          [responsive]="true"
          showEffect="fade"
          [modal]="true"
          [style]="{ width: '980px' }"
          [positionTop]="10"
          (onAfterHide)="onDialogHide()"
        >
          <div class="field grid flex" *ngIf="selectedCliente">
            <div class="col-6" style="text-align: center">
              <img
                src="../../../assets/images/{{ selectedCliente.nome }}.png"
                style="max-width: 100px; max-height: 100px"
              />
            </div>

            <div class="col-6">
              <h2>
                {{ selectedCliente.nome }}
              </h2>
            </div>

            <div class="col-12 flex">
              <p-table
                [value]="periodos"
                [scrollable]="true"
                scrollHeight="400px"
                dataKey="id"
                [expandedRowKeys]="expandedRows"
                [responsive]="true"
                responsiveLayout="stack"
                styleClass="p-datatable-striped"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Id</th>
                    <th>Descrição</th>
                    <th>Valor Hora</th>
                    <th>Imposto</th>
                    <th>Atendimentos</th>
                    <th>Ações</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-periodo>
                  <tr>
                    <td>
                      <i class="pi {{ periodo.icon }}"></i>
                      <span class="p-column-title">Id: </span>{{ periodo.id }}
                    </td>
                    <td>
                      <span class="p-column-title">Descrição: </span
                      >{{ periodo.descricao }}
                    </td>
                    <td>
                      <span class="p-column-title">Valor Hora: </span
                      >{{ periodo.valor_hora }}
                    </td>
                    <td>
                      <span class="p-column-title">Imposto: </span
                      >{{ periodo.porcentagem_imposto }}%
                    </td>
                    <td>
                      <span class="p-column-title">Atendimentos: </span
                      >{{ periodo.quantidade_atendimentos }}
                    </td>
                    <td>
                      <p-button
                        type="button"
                        icon="pi pi-pencil"
                        severity="success"
                        class="mr-2"
                        (onClick)="editperiodo(periodo)"
                        label="Editar"
                      ></p-button>
                      <p-button
                        *ngIf="periodo.quantidade_atendimentos === 0"
                        type="button"
                        icon="pi pi-times"
                        severity="danger"
                        class="mr-2"
                        (onClick)="deleteperiodo(periodo)"
                        label="Excluir"
                      ></p-button>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary" let-rowData>
                  <div style="text-align: left">
                    <p-button
                      type="button"
                      icon="pi pi-plus"
                      severity="primary"
                      (onClick)="showDialogToAdd()"
                      label="Incluir"
                    ></p-button>
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-dialog>

        <app-periodo-edit
          [selectedCliente]="selectedCliente"
          [editMode]="editMode"
          (updateList)="getperiodos($event)"
        >
        </app-periodo-edit>
      </div>
    </div>
  </div>
</section>
