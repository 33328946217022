import { HttpClient } from '@angular/common/http';
import { Atendimento } from './../../domains/atendimento';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';

import { Observable } from 'rxjs';
import { Cliente } from '../../domains/cliente';
import { User } from '../../auth/user.model';
import { Periodo } from '../../domains/periodo';
import { TecnicoInterface } from '../../domains/tecnico';
import { environment } from '../../../environments/environment';
import { Result } from '../../domains/result';

@Component({
  selector: 'app-atendimento-detail',
  templateUrl: './atendimento-detail.component.html',
  styleUrls: ['./atendimento-detail.component.css'],
  standalone: false,
})
export class AtendimentoDetailComponent implements OnInit {
  @Input()
  atendimentoOriginal: Atendimento = new Atendimento();
  @Input()
  selectedCliente: Cliente = undefined as any;
  selectedCli: string | number = 0;
  @Input()
  profile: User = new User();
  @Input()
  selectedPeriodo: Periodo = new Periodo();
  selectedPer: string | number = 0;
  @Input()
  readonly: boolean = false;

  @Input()
  allperiodos: Periodo[] = [];
  periodos: Periodo[] = [];

  @Input()
  allClientes: Cliente[] = [];
  clientes: Cliente[] = [];

  @Output()
  loadBrowser = new EventEmitter<boolean>();

  atendimento: Atendimento = new Atendimento();
  analistas: TecnicoInterface[] = [];
  analistasAtivos: TecnicoInterface[] = [];
  canaisSolicitacao = [];
  selectedAnalista: TecnicoInterface = undefined as any;
  enviando = false;
  loadingsolicitante = false;

  duration = 15;

  minDate: Date = new Date();
  maxDate: Date = new Date();
  hoje: Date = new Date();

  showDialog = true;
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    (this.selectedCli as any) = this.selectedCliente.id;
    this.selectedPer = this.selectedPeriodo.id;
    this.atendimento = new Atendimento(this.atendimentoOriginal);

    this.clientes = this.allClientes.filter((x) => {
      return x.id !== '*pgto*' && x.id !== '*emis*';
    });

    this.changeCliente();

    if (!this.atendimento.id) {
      this.atendimento.data_inicio = new Date();
      this.atendimento.data_fim = undefined as any;
      this.atendimento.descricao = '';
    }

    this.analistas.forEach((x) => {
      if (this.atendimento) {
        if (x.id === this.atendimento.tecnico_id) {
          this.selectedAnalista = x;
        } else if (x.logged && !this.atendimento.id) {
          this.selectedAnalista = x;
        }
      }
    });

    this.changeDates();

    if (this.profile.perfil === 'Administrador') {
      this.minDate = new Date(this.selectedPeriodo.data_inicio);
    } else {
      this.minDate = new Date();
      this.minDate.setDate(
        this.minDate.getDate() - this.selectedPeriodo.dias_liberados
      );
      if (this.minDate > this.atendimento.data_inicio) {
        this.minDate = this.atendimento.data_inicio;
      }
    }

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    if (this.maxDate < this.atendimento.data_fim) {
      this.maxDate = this.atendimento.data_fim;
    }

    const url = environment.apiUrl + '/technician';

    this.http.get<Result<TecnicoInterface[]>>(url).subscribe((result) => {
      this.analistas = result.data;

      // no mostra o analista se for ativo ou se estiver em edição para inclusões não mostrarem todos
      this.analistasAtivos = result.data.filter((x: any) => {
        return x.ativo || this.atendimento.id;
      });

      this.analistas.forEach((x) => {
        if (this.atendimento) {
          if (x.id === this.atendimento.tecnico_id) {
            this.selectedAnalista = x;
          } else if (x.logged && !this.atendimento.id) {
            this.selectedAnalista = x;
          }
        }
      });
    });
  }

  changeCliente() {
    (this.selectedCliente as any) = this.clientes.find((x) => {
      return x.id === this.selectedCli;
    });
    this.periodos = this.allperiodos.filter((x) => {
      return (
        x.cliente_id === this.selectedCliente.id &&
        (this.readonly || x.status === 'Aberto')
      );
    });
    this.changeperiodo();
  }

  changeperiodo() {
    (this.selectedPeriodo as any) = this.periodos.find((x) => {
      return x.id === this.selectedPer;
    });
  }

  changeDuration() {
    // verifica se está quebrada
    if (this.duration % 15 > 0) {
      this.duration += 15 - (this.duration % 15);
    }

    this.atendimento.data_fim = new Date(
      this.atendimento.data_inicio.toString()
    );
    this.atendimento.data_fim = new Date(
      this.atendimento.data_fim.getTime() + this.duration * 60000
    );
  }

  changeDates() {
    this.atendimento.data_inicio.setSeconds(0);
    let minutes = this.atendimento.data_inicio.getMinutes();

    let m = (Math.round(minutes / 15) * 15) % 60;
    // var h = ((((minutes / 105) + .5) | 0) + hours) % 24;

    this.atendimento.data_inicio.setMinutes(m);

    if (this.atendimento.data_fim) {
      this.atendimento.data_fim = new Date(
        this.atendimento.data_fim.toString()
      );
      minutes = this.atendimento.data_fim.getMinutes();
      if (minutes % 15 > 0) {
        this.atendimento.data_fim.setMinutes(minutes + 15 - (minutes % 15));
      }
    } else {
      this.atendimento.data_fim = new Date(
        this.atendimento.data_inicio.toString()
      );
      minutes = this.atendimento.data_fim.getMinutes();
      m = (Math.round(minutes / 15) * 15) % 60;
      this.atendimento.data_fim.setMinutes(m + 15);
    }

    this.duration =
      new Date(this.atendimento.data_fim.toString()).getTime() -
      new Date(this.atendimento.data_inicio.toString()).getTime();
    this.duration = this.duration / 1000 / 60;
  }

  cancel() {
    this.loadBrowser.emit(false);
  }

  save() {
    const url = environment.apiUrl + '/serviceOrder/';
    const tempAtendimento = {} as Atendimento;

    // verifica se as datas não estão erradas e se tudo foi preenchido
    if (this.atendimento.data_fim <= this.atendimento.data_inicio) {
      this.messageService.add({
        severity: 'info',
        summary: 'Erro Data Final',
        detail: 'Data Final deve ser maior que a data Inicial.',
        life: 15000,
      });
      return;
    }

    if (!(this.atendimento.descricao.length > 10)) {
      this.messageService.add({
        severity: 'info',
        summary: 'Dados Incompletos',
        detail: 'Os campos de Descrição não tem 10 caracteres.',
        life: 15000,
      });
      return;
    }

    if (!(this.atendimento.descricao.length > 10)) {
      this.messageService.add({
        severity: 'info',
        summary: 'Dados Incompletos',
        detail: 'Os campos de Descrição não tem 10 caracteres.',
        life: 15000,
      });
      return;
    }

    if (this.selectedCliente.solicitante || this.selectedPeriodo.solicitante) {
      if (!this.atendimento.solicitante) {
        this.messageService.add({
          severity: 'info',
          summary: 'Dados Incompletos',
          detail: 'Preencha o solicitante.',
          life: 15000,
        });
        return;
      }
      if (this.atendimento.solicitante.length < 4) {
        this.messageService.add({
          severity: 'info',
          summary: 'Dados Incompletos',
          detail: 'O nome do solicitante deve ter pelo menos 4 letras.',
          life: 15000,
        });
        return;
      }
    }

    if (this.selectedPeriodo.informa_chamado) {
      if (
        !this.atendimento.chamado ||
        parseInt(this.atendimento.chamado) === 0
      ) {
        this.messageService.add({
          severity: 'info',
          summary: 'Dados Incompletos',
          detail: 'Preencha o Número do chamado.',
          life: 15000,
        });
        return;
      }
    }

    if (this.atendimento.solicitante) {
      tempAtendimento.solicitante = this.atendimento.solicitante;
    }

    if (this.atendimento.chamado) {
      tempAtendimento.chamado = this.atendimento.chamado;
    }

    if (this.selectedPeriodo.checa_hora && this.duration >= 300) {
      this.messageService.add({
        severity: 'info',
        summary: 'Duração',
        life: 15000,
        detail:
          'Para esse período a duração da ordem de serviço não deve exceder 5 horas consecutivas.',
      });
      return;
    }

    if (
      (this.selectedCliente.data_solicitacao ||
        this.selectedPeriodo.data_solicitacao) &&
      !this.atendimento.data_solicitacao
    ) {
      this.messageService.add({
        severity: 'info',
        summary: 'Dados Incompletos',
        detail: 'Preencha a Data de Solicitação.',
        life: 15000,
      });
      return;
    } else if (this.atendimento.data_solicitacao) {
      tempAtendimento.data_solicitacao = this.atendimento.data_solicitacao;
    }

    (tempAtendimento as any).cliente_id = this.selectedCliente.id;
    tempAtendimento.periodo_id = this.selectedPeriodo.id;
    tempAtendimento.data_inicio = this.atendimento.data_inicio;
    tempAtendimento.data_fim = this.atendimento.data_fim;
    tempAtendimento.descricao = this.atendimento.descricao;
    tempAtendimento.abonada = this.atendimento.abonada;
    if (this.atendimento.id) {
      tempAtendimento.id = this.atendimento.id;
      tempAtendimento.tecnico_id = this.atendimento.tecnico_id;
    } else if (this.profile.perfil === 'Analista') {
      tempAtendimento.tecnico_id = this.profile.tecnico as any;
    } else if (
      this.profile.perfil === 'Administrador' &&
      this.selectedAnalista
    ) {
      tempAtendimento.tecnico_id = this.selectedAnalista.id;
    }
    this.enviando = true;
    let subscribe: Observable<Object>;

    if (this.atendimento.id) {
      subscribe = this.http.put(url + this.atendimento.id, tempAtendimento);
    } else {
      subscribe = this.http.post(url, tempAtendimento);
    }

    subscribe.subscribe(
      () => {
        this.loadBrowser.emit(true);
        this.messageService.add({
          severity: 'success',
          summary: this.atendimento.id
            ? 'Edição'
            : 'Inclusão' + ' efetuada com sucesso',
          detail: 'Sua operação foi realizada.',
          life: 15000,
        });
      },
      (error) => {
        // console.log(error);

        this.messageService.add({
          severity: 'error',
          summary: 'Falha na ' + this.atendimento.id ? 'edição' : 'inclusão',
          detail: error.message,
          life: 15000,
        });
        this.enviando = false;
      }
    );
  }

  delete() {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir o atendimento?',
      accept: () => {
        const url = environment.apiUrl + '/serviceOrder/' + this.atendimento.id;
        this.http.delete(url).subscribe(
          () => {
            // console.log('emitter');
            this.loadBrowser.emit(true);
            this.messageService.add({
              severity: 'success',
              summary: 'Exclusão efetuada com sucesso',
              detail: 'Sua exclusão foi realizada.',
              life: 15000,
            });
          },
          (error) => {
            // console.log(error);

            this.messageService.add({
              severity: 'error',
              summary: 'Falha na exclusão',
              detail: 'Via MessageService',
              life: 15000,
            });
          }
        );
      },
    });
  }
}
