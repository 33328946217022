import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, HostBinding, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const _c0 = ["*"];
const classes = {
  root: 'p-avatar-group p-component'
};
class AvatarGroupStyle extends BaseStyle {
  name = 'avatargroup';
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵAvatarGroupStyle_BaseFactory;
    return function AvatarGroupStyle_Factory(__ngFactoryType__) {
      return (ɵAvatarGroupStyle_BaseFactory || (ɵAvatarGroupStyle_BaseFactory = i0.ɵɵgetInheritedFactory(AvatarGroupStyle)))(__ngFactoryType__ || AvatarGroupStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AvatarGroupStyle,
    factory: AvatarGroupStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AvatarGroupStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * A set of Avatars can be displayed together using the AvatarGroup component.
 *
 * [Live Demo](https://www.primeng.org/avatar/)
 *
 * @module avatargroupstyle
 *
 */
var AvatarGroupClasses;
(function (AvatarGroupClasses) {
  AvatarGroupClasses["root"] = "p-avatar-group";
})(AvatarGroupClasses || (AvatarGroupClasses = {}));

/**
 * AvatarGroup is a helper component for Avatar.
 * @group Components
 */
class AvatarGroup extends BaseComponent {
  /**
   * Style class of the component
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  get hostClass() {
    return this.styleClass;
  }
  get hostStyle() {
    return this.style;
  }
  _componentStyle = inject(AvatarGroupStyle);
  static ɵfac = /* @__PURE__ */(() => {
    let ɵAvatarGroup_BaseFactory;
    return function AvatarGroup_Factory(__ngFactoryType__) {
      return (ɵAvatarGroup_BaseFactory || (ɵAvatarGroup_BaseFactory = i0.ɵɵgetInheritedFactory(AvatarGroup)))(__ngFactoryType__ || AvatarGroup);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: AvatarGroup,
    selectors: [["p-avatarGroup"], ["p-avatar-group"], ["p-avatargroup"]],
    hostVars: 8,
    hostBindings: function AvatarGroup_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵstyleMap(ctx.hostStyle);
        i0.ɵɵclassMap(ctx.hostClass);
        i0.ɵɵclassProp("p-avatar-group", true)("p-component", true);
      }
    },
    inputs: {
      styleClass: "styleClass",
      style: "style"
    },
    features: [i0.ɵɵProvidersFeature([AvatarGroupStyle]), i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function AvatarGroup_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    dependencies: [CommonModule, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AvatarGroup, [{
    type: Component,
    args: [{
      selector: 'p-avatarGroup, p-avatar-group, p-avatargroup',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: ` <ng-content></ng-content> `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [AvatarGroupStyle],
      host: {
        '[class.p-avatar-group]': 'true',
        '[class.p-component]': 'true'
      }
    }]
  }], null, {
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }],
    hostStyle: [{
      type: HostBinding,
      args: ['style']
    }]
  });
})();
class AvatarGroupModule {
  static ɵfac = function AvatarGroupModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AvatarGroupModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AvatarGroupModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [AvatarGroup, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AvatarGroupModule, [{
    type: NgModule,
    args: [{
      imports: [AvatarGroup, SharedModule],
      exports: [AvatarGroup, SharedModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AvatarGroup, AvatarGroupClasses, AvatarGroupModule, AvatarGroupStyle };
