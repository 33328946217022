import { Usuario } from './usuarios';

export class Tecnico {
  id!: number;
  nome!: string;
  ativo: boolean = false;
  chave_pix!: string;
  usuario?: Usuario;
  rateios: rateios_tecnico[] = [];
}

export class rateios_tecnico {
  id!: number;
  tecnico_id!: number;
  cliente_id!: number;
  periodo_id!: number;
  tecnico_recebedor_id!: number;
  porcentagem!: number;
  valor_hora!: number;
}

export class TecnicoInterface {
  id!: number;
  nome!: string;
  decimal!: number;
  ativo: boolean = false;
  logged: boolean = false;

  // dados de agrupamentos
  xId?: string;
  nome_cliente?: string;
  total_tempo?: number;
  total_bruto?: number;
  total_imposto?: number;
  total_distribuicao?: number;
  total_receber?: number;
  atendimentos?: AtendimentosPorCliente[];
}

export interface AtendimentosPorCliente {
  nome_cliente: string;
  xId: string;
  total_tempo?: number;
  total_bruto?: number;
  total_imposto?: number;
  total_distribuicao?: number;
  total_receber?: number;
  periodos?: AtendimentosPorperiodo[];
}

export interface AtendimentosPorperiodo {
  xId: string;
  descricao_periodo: string;
  total_tempo?: number;
  total_bruto?: number;
  total_imposto?: number;
  total_distribuicao?: number;
  total_receber?: number;
}
