import { Perfis } from './../../domains/enum/perfis';
import { Atendimento } from '../../../app/domains/atendimento';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Periodo } from '../../../app/domains/periodo';
import { Result } from '../../../app/domains/result';
import { TecnicoInterface } from '../../../app/domains/tecnico';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import { Cliente } from '../../../app/domains/cliente';
import { AuthService } from '../../../app/auth/auth.service';
import { User } from '../../../app/auth/user.model';
import { environment } from '../../../environments/environment';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-atendimento-list',
  templateUrl: './atendimento-list.component.html',
  styleUrls: ['./atendimento-list.component.css'],
  providers: [DecimalPipe],
  standalone: false,
})
export class AtendimentoListComponent implements OnInit {
  displayDialog: boolean = false;
  atendimentos: Atendimento[] = [];
  atendimentosOriginal: Atendimento[] = [];
  selectedAtendimento!: Atendimento;
  atendimento: any = {};
  cols: any[] = [];
  disabled: boolean = false;
  loading = false;

  total_tempo!: number;
  total_bruto!: number;
  total_distribuicao!: number;
  total_imposto!: number;

  clientes: Cliente[] = [];
  nomesClientes: any[] = [];
  periodos: Periodo[] = [];
  rangeDates: Date[] | undefined;
  allperiodos: Periodo[] = [];
  selectedCliente: any;
  selectedClienteObj!: Cliente;
  selectedPeriodo!: string | number;
  selectedPeriodoObj: Periodo = <Periodo>{};
  selectedFilial!: number;
  filiaisOriginal: any[] = [];
  filiais: any[] = [];
  get profile(): User {
    return this.authorizeService.getUser();
  }
  perfis = Perfis;
  get nomesAnalistas(): any[] {
    const nomesAnalistas: string[] = [];
    this.analistas.forEach((x) => {
      nomesAnalistas.push(x.nome);
    });
    return nomesAnalistas;
  }
  analistas: TecnicoInterface[] = [];

  clienteRota = null as any;
  periodoRota = null as any;

  expandedRows = {};
  expandedRowsSubItens = {};

  enviando = false;

  @ViewChild('tblAtendimentos')
  tblAtendimentos: any;

  constructor(
    private http: HttpClient,
    private cp: CurrencyPipe,
    public dp: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private authorizeService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.disabled = true;

    //obtem todos os períodos sem filtros
    this.http.get<Result<Periodo[]>>(environment.apiUrl + '/branch').subscribe(
      (res) => {
        this.filiaisOriginal = res.data;
        this.filiaisOriginal.push({ Id: null, Nome: 'Sem Filial' });

        this.http
          .get<Result<Periodo[]>>(environment.apiUrl + '/period')
          .subscribe(
            (res) => {
              this.allperiodos = res.data;

              this.http
                .get<Result<TecnicoInterface[]>>(
                  environment.apiUrl + '/technician'
                )
                .subscribe(
                  (result) => {
                    this.analistas = [];
                    result.data.forEach((x) => {
                      x.total_bruto = 0;
                      x.total_receber = 0;
                      this.analistas.push(x);
                    });

                    this.analistas = this.analistas.sort((a, b) => {
                      return a.nome.localeCompare(b.nome);
                    });

                    this.analistas.push({
                      id: 0,
                      nome: 'Comissão de outros analistas',
                      ativo: false,
                      logged: undefined as any,
                      decimal: 0,
                      total_bruto: 0,
                      total_receber: 0,
                    });
                    this.http
                      .get<Result<Cliente[]>>(environment.apiUrl + '/customers')
                      .subscribe((res) => {
                        this.clientes = res.data;

                        this.nomesClientes = [];
                        this.clientes.forEach((x) => {
                          this.nomesClientes.push(x.nome);
                        });

                        this.updatePerfil();
                        this.route.params.subscribe((x: any) => {
                          this.clienteRota = x.cliente;
                          this.periodoRota = x.periodo;

                          const clientObj = this.clientes.find((y) => {
                            return y.id == x.cliente;
                          });
                          const periodoObj = this.allperiodos.find((y) => {
                            return y.id == x.periodo;
                          });
                          if (x && x.cliente && clientObj) {
                            this.selectedCliente = clientObj.id;
                            if (periodoObj) {
                              this.selectedPeriodo = periodoObj.id;
                            }
                          }
                          this.onChangeCliente();
                        });
                      });
                  },
                  (error) => {
                    console.log(error);
                    if (error.status === 401) {
                      this.authorizeService.logout();
                    } else {
                      this.messageService.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Erro ao buscar os técnicos',
                      });
                    }
                  }
                );
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                this.authorizeService.logout();
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erro',
                  detail: 'Erro ao buscar os períodos',
                });
              }
            }
          );
      },
      (error) => {
        console.log(error);
        if (error.status === 401) {
          this.authorizeService.logout();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro ao buscar as filiais',
          });
        }
      }
    );
  }

  get tecnicosFilter(): TecnicoInterface[] {
    const list: TecnicoInterface[] = this.analistas;

    list.unshift({
      id: undefined as any,
      nome: 'Todos',
      decimal: 0,
      ativo: false,
      logged: false,
    });
    return list;
  }

  updatePerfil(): void {
    // verifica se tem que colocar mais itens na lista de clientes
    if (
      this.clientes.length &&
      !this.clientes.find((x) => {
        return x.id === '*pgto*';
      })
    ) {
      // se tiver mais de um cliente permite selecionar todos
      if (this.clientes.length > 1) {
        this.clientes.push({ nome: 'Digitacão', id: '*digit*' });
        this.clientes.push({ nome: 'Recebimento', id: '*receb*' });
        this.clientes.push({ nome: 'Todos Pagamento', id: '*pgto*' });
        if (this.profile && this.profile.perfil === 'Administrador') {
          this.clientes.push({ nome: 'Todos Emissão', id: '*emis*' });
        }
      }
    }
  }

  onChangeCliente() {
    if (this.tblAtendimentos) {
      this.tblAtendimentos.filter(null, 'nome_cliente', 'in');
    }

    if (this.selectedCliente) {
      this.selectedClienteObj = this.clientes.find(
        (e) => e.id === this.selectedCliente
      ) as any;
    } else {
      this.selectedClienteObj = this.clientes[0];
    }

    this.selectedCliente = this.selectedClienteObj.id;

    this.getperiodos(this.selectedCliente);
  }

  onChangeperiodo() {
    this.loadAtendimentos(true);
  }

  onChangeFilial() {
    this.tblAtendimentos.filter(this.selectedFilial, 'filial_id', 'equals');
  }

  getCliente(atendimento: Atendimento): Cliente {
    const id = atendimento ? atendimento.cliente_id : this.selectedCliente;
    return this.clientes.find((e) => {
      return e.id === id;
    }) as any;
  }

  getperiodo(atendimento: Atendimento): Periodo {
    const id = atendimento ? atendimento.periodo_id : this.selectedPeriodo;
    return (
      this.allperiodos
        ? this.allperiodos.find((e) => (e['id'] as any) === id)
        : undefined
    ) as any;
  }

  periodoAberto(atendimento?: Atendimento): boolean {
    const periodo = this.getperiodo(atendimento as Atendimento);

    return periodo && periodo.status === 'Aberto';
  }

  getperiodos(
    cliente_id: number,
    periodoUrl: number = this.selectedPeriodo as any
  ) {
    if (
      this.selectedCliente === '*digit*' ||
      this.selectedCliente === '*receb*'
    ) {
      return;
    }
    let periodosTmp: any[] = [];
    let url = environment.apiUrl + '/customers';
    if (
      this.selectedCliente !== '*pgto*' &&
      this.selectedCliente !== '*emis*'
    ) {
      url += '/' + cliente_id;
    }

    this.http.get<Result<Cliente>>(url).subscribe(
      (res: any) => {
        if (res) {
          if (this.selectedClienteObj.id === '*pgto*') {
            res = res['pagamentos'];
            res.data.periodos.find((periodo: any) => {
              periodo.cliente_id = this.selectedClienteObj.id;
            });
          } else if (this.selectedClienteObj.id === '*emis*') {
            res = res['emissoes'];
            res.data.periodos.find((periodo: any) => {
              periodo.cliente_id = this.selectedClienteObj.id;
            });
          }

          // adiciona os anos
          if (
            this.selectedClienteObj.id === '*pgto*' ||
            this.selectedClienteObj.id === '*emis*'
          ) {
            res.data.periodos.forEach((p: any) => {
              if (
                !res.data.periodos.find((periodo: any) => {
                  if (p.id) {
                    return periodo.id === (p.id as any).split('-')[0];
                  } else {
                    return periodo.id === p.id;
                  }
                })
              ) {
                if (p.id) {
                  const item = { id: (p.id as any).split('-')[0] } as any;
                  item.descricao = item.value;
                  item.cliente_id = p.cliente_id;
                  res.data.periodos.push(item);
                } else {
                  res.data.periodos.push({
                    id: p.id,
                    descricao: 'Sem Data',
                    cliente_id: p.cliente_id,
                  } as any);
                }
              }
            });
          }

          const datePipe = new DatePipe('pt-BR');
          res.data.periodos.forEach((periodo: any) => {
            if (periodo.id) {
              if (!periodo.descricao) {
                periodo.descricao = datePipe.transform(
                  new Date(periodo.id + '-28'),
                  'MM-yyyy'
                );
              }
              periodosTmp.push(periodo);
            } else {
              periodosTmp.push({
                id: periodo.id,
                descricao: 'Sem Data',
                cliente_id: periodo.cliente_id,
              } as any);
            }
          });

          if (
            this.selectedClienteObj.id === '*pgto*' ||
            this.selectedClienteObj.id === '*emis*'
          ) {
            periodosTmp = periodosTmp.sort((a: Periodo, b: Periodo) => {
              // trata nulos
              if (!a.id) {
                return -1;
              }
              if (!b.id) {
                return 1;
              }

              const valueA = (a.id as any).split('-');
              const valueB = (b.id as any).split('-');

              if (valueA[1]) {
                valueA[1] = (valueA[1].length === 1 ? '0' : '') + valueA[1];
              } else {
                valueA.push('');
              }
              if (valueB[1]) {
                valueB[1] = (valueB[1].length === 1 ? '0' : '') + valueB[1];
              } else {
                valueB.push('');
              }

              return valueA[0] + valueA[1] > valueB[0] + valueB[1] ? -1 : 1;
            });

            periodosTmp.push({
              id: 'all',
              nome: 'Todos',
              cliente_id: this.selectedCliente,
            });
          } else {
            periodosTmp = periodosTmp.sort((a: Periodo, b: Periodo) => {
              // ordena pelo id
              return a.id > b.id ? -1 : 1;
            });
          }

          if (periodosTmp.length > 0) {
            const perObj = periodosTmp.find((y) => {
              return (
                y.id == periodoUrl &&
                y.cliente_id === this.selectedClienteObj.id
              );
            });

            this.periodos = periodosTmp;
            // tah ficando zuada
            this.selectedCliente = this.selectedClienteObj.id;
            if (perObj) {
              this.selectedPeriodo = perObj.id;
              this.selectedPeriodoObj = perObj;
            } else {
              this.selectedPeriodo = periodosTmp[0].id;
              this.selectedPeriodoObj = periodosTmp[0];
            }
            this.loadAtendimentos(true);
          }
        }
      },
      (error) => {
        console.log(error);
        if (error.status === 401) {
          this.authorizeService.logout();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro ao buscar os períodos',
          });
        }
      }
    );
  }

  loadAtendimentos(atualiza: boolean) {
    // se já está atualiznaod espera
    if (this.loading) {
      return;
    }

    this.atendimentos = [];
    this.atendimentosOriginal = [];
    this.loading = true;
    const rangeDates: any = this.rangeDates;
    if (
      (this.selectedCliente === '*digit*' ||
        this.selectedCliente === '*receb*') &&
      (!rangeDates[0] || !rangeDates[1])
    ) {
      // aguarda selecionar a segunda data
    } else if (!atualiza) {
      this.selectedAtendimento = undefined as any;
      this.displayDialog = false;
      this.loading = false;
    } else {
      this.periodoRota =
        this.periodoRota === 'null' || this.periodoRota === 'undefined'
          ? null
          : this.periodoRota;

      if (
        this.clienteRota !== '' + this.selectedCliente ||
        '' + this.periodoRota !== '' + this.selectedPeriodo
      ) {
        this.loading = false;
        this.navigate();
      } else {
        this.selectedAtendimento = undefined as any;
        this.displayDialog = false;
        this.total_bruto = undefined as any;
        this.total_tempo = undefined as any;
        this.total_distribuicao = undefined as any;
        this.updatePerfil();

        this.expandedRows = {};
        this.expandedRowsSubItens = {};
        this.total_bruto = 0;
        this.total_distribuicao = 0;
        this.total_imposto = 0;
        this.total_tempo = 0;

        let url: string;

        this.selectedClienteObj = this.clientes.find(
          (e) => e.id === this.selectedCliente
        ) as any;
        if (
          this.selectedCliente !== '*digit*' &&
          this.selectedCliente !== '*receb*'
        ) {
          this.selectedPeriodoObj = this.periodos.find(
            (e) => e.id === this.selectedPeriodo
          ) as any;
        }

        // monta lista de colunas
        this.cols = [];
        this.cols.push({ field: 'id', header: 'id' });
        this.cols.push({ field: 'nome_tecnico', header: 'Nome Tecnico' });
        if (
          this.profile &&
          (this.selectedCliente === '*pgto*' ||
            this.selectedCliente === '*emis*' ||
            this.selectedCliente === '*digit*' ||
            this.selectedCliente === '*receb*') &&
          (this.profile.perfil === Perfis.Administrador ||
            this.profile.perfil === Perfis.Cliente)
        ) {
          this.cols.push({
            field: 'nome_cliente',
            header: 'Cliente',
          });
          this.cols.push({
            field: 'descricao_periodo',
            header: 'Período',
          });
        }
        if (
          this.selectedClienteObj.solicitante ||
          (this.selectedPeriodoObj && this.selectedPeriodoObj.solicitante)
        ) {
          this.cols.push({ field: 'solicitante', header: 'solicitante' });
        }
        this.cols.push({
          field: 'data_inicio',
          header: 'Inicio',
          type: this.dp,
          arg1: 'dd/MM/yyyy HH:mm',
        });
        this.cols.push({
          field: 'data_fim',
          header: 'Fim',
          type: this.dp,
          arg1: 'dd/MM/yyyy HH:mm',
        });
        this.cols.push({ field: 'duracao', header: 'Duração' });

        if (
          this.profile &&
          (this.profile.perfil === Perfis.Administrador ||
            this.profile.perfil === Perfis.Cliente)
        ) {
          this.cols.push({
            field: 'valor_bruto',
            header: 'Valor bruto',
            type: this.cp,
            arg1: 'BRL',
            class: 'align-end',
          });
        }

        if (
          this.profile &&
          (this.profile.perfil === Perfis.Administrador ||
            this.profile.perfil === Perfis.Analista)
        ) {
          this.cols.push({
            field: 'valor_receber',
            header: 'A Receber',
            type: this.cp,
            arg1: 'BRL',
            class: 'align-end',
          });
        }
        if (this.profile && this.profile.perfil === Perfis.Administrador) {
          this.cols.push({
            field: 'valor_tecnico',
            header: 'Valor Tecnico A Receber',
            type: this.cp,
            arg1: 'BRL',
            class: 'align-end',
          });
        }

        this.displayDialog = false;

        if (this.selectedCliente === '*digit*') {
          url =
            environment.apiUrl +
            'api/atendimento/data/digitacao/' +
            rangeDates[0].toISOString() +
            '/' +
            rangeDates[1].toISOString();
        } else if (this.selectedCliente === '*receb*') {
          url =
            environment.apiUrl +
            'api/atendimento/data/pagamento/' +
            rangeDates[1] +
            '/' +
            rangeDates[1];
        } else if (this.selectedCliente === '*pgto*') {
          url =
            environment.apiUrl +
            'api/atendimento/pagamento/' +
            this.selectedPeriodo;
        } else if (this.selectedCliente === '*emis*') {
          url =
            environment.apiUrl +
            'api/atendimento/emissao/' +
            this.selectedPeriodo;
        } else {
          url = environment.apiUrl + '/period/' + this.selectedPeriodo;
        }

        this.http.get<Result<Periodo>>(url).subscribe(
          (result) => {
            const tempAtendimentos: Atendimento[] = [];
            result.data.atendimentos.forEach((atendimentoResult) => {
              const atendimento = new Atendimento(atendimentoResult);
              atendimento.nome_tecnico = this.analistas.find((x) => {
                return x.id === atendimento.tecnico_id;
              })?.nome as any;
              tempAtendimentos.push(atendimento);
            });

            // ajusta periodoSelecionado
            if (
              this.selectedCliente !== '*digit*' &&
              this.selectedCliente !== '*receb*'
            ) {
              const perObj = this.periodos.find((y) => {
                return (
                  y.id == this.periodoRota && y.cliente_id === this.clienteRota
                );
              });

              if (perObj) {
                this.selectedPeriodo = perObj.id;
                this.selectedPeriodoObj = perObj;
              }
            }

            this.atendimentosOriginal = tempAtendimentos;
            this.atendimentos = JSON.parse(JSON.stringify(tempAtendimentos));

            // Atualiza lista de filiais com os dados do Período
            this.filiais = this.filiaisOriginal.filter((y) => {
              return this.atendimentosOriginal.find((x) => {
                return x.filial_id === y.Id;
              });
            });

            this.CalculaTotais();
          },
          (error) => {
            console.error(error);
            if (error.status === 401) {
              this.authorizeService.logout();
            }
          }
        );
      }
    }
  }

  onFilter($event: any) {
    this.loading = true;
    this.atendimentos = $event.filteredValue;
    this.CalculaTotais();
  }

  CalculaTotais() {
    // Zera Totais Gerais
    this.total_bruto = 0;
    this.total_distribuicao = 0;
    this.total_imposto = 0;
    this.total_tempo = 0;

    // zera os atendimentos do analista
    this.analistas.forEach((analista) => {
      analista.atendimentos = [];
      analista.total_bruto = 0;
      analista.total_imposto = 0;
      analista.total_receber = 0;
      analista.total_distribuicao = 0;
      analista.total_tempo = 0;
    });

    // verifica atendimentos com horários em conflito
    this.atendimentos.forEach((atendimento: Atendimento) => {
      atendimento.valor_receber = 0;
      atendimento.nome_cliente = this.clientes.find((x) => {
        return x.id === atendimento.cliente_id;
      })?.nome as any;

      const analistaAtendimento = this.analistas.find((x) => {
        return x.id === atendimento.tecnico_id;
      });

      // Totaliza no cliente
      let AgrupamentoCliente = analistaAtendimento?.atendimentos?.find((x) => {
        return x.xId === atendimento.tecnico_id + '|' + atendimento.cliente_id;
      });

      if (!AgrupamentoCliente) {
        AgrupamentoCliente = {
          xId: atendimento.tecnico_id + '|' + atendimento.cliente_id,
          nome_cliente: atendimento.nome_cliente as any,
          periodos: [],
          total_bruto: 0,
          total_distribuicao: 0,
          total_imposto: 0,
          total_receber: 0,
          total_tempo: 0,
        };
        analistaAtendimento?.atendimentos?.push(AgrupamentoCliente);
      }

      // Totaliza no período
      let Agrupamentoperiodo = AgrupamentoCliente?.periodos?.find((x) => {
        return (
          x.xId ===
          atendimento.tecnico_id +
            '|' +
            atendimento.cliente_id +
            '|' +
            atendimento.periodo_id
        );
      });

      if (!Agrupamentoperiodo) {
        Agrupamentoperiodo = {
          xId:
            atendimento.tecnico_id +
            '|' +
            atendimento.cliente_id +
            '|' +
            atendimento.periodo_id,
          descricao_periodo: atendimento.descricao_periodo,
          total_bruto: 0,
          total_distribuicao: 0,
          total_imposto: 0,
          total_receber: 0,
          total_tempo: 0,
        };
        AgrupamentoCliente?.periodos?.push(Agrupamentoperiodo);
      }

      // Adiciona no Total do Analista
      atendimento.rateios.forEach((rateio) => {
        const analista = this.analistas.find((x) => {
          return x.id === rateio.tecnico_id;
        });
        // Totaliza no cliente
        let AgrupamentoAnalistaCliente = analista?.atendimentos?.find((x) => {
          return x.xId === rateio.tecnico_id + '|' + atendimento.cliente_id;
        });

        if (!AgrupamentoAnalistaCliente) {
          AgrupamentoAnalistaCliente = {
            xId: rateio.tecnico_id + '|' + atendimento.cliente_id,
            nome_cliente: atendimento.nome_cliente as any,
            periodos: [],
            total_bruto: 0,
            total_distribuicao: 0,
            total_imposto: 0,
            total_receber: 0,
            total_tempo: 0,
          };
          analista?.atendimentos?.push(AgrupamentoAnalistaCliente);
        }

        // Totaliza no período
        let AgrupamentoAnalistaperiodo =
          AgrupamentoAnalistaCliente?.periodos?.find((x) => {
            return (
              x.xId ===
              rateio.tecnico_id +
                '|' +
                atendimento.cliente_id +
                '|' +
                atendimento.periodo_id
            );
          });

        if (!AgrupamentoAnalistaperiodo) {
          AgrupamentoAnalistaperiodo = {
            xId:
              rateio.tecnico_id +
              '|' +
              atendimento.cliente_id +
              '|' +
              atendimento.periodo_id,
            descricao_periodo: atendimento.descricao_periodo,
            total_bruto: 0,
            total_distribuicao: 0,
            total_imposto: 0,
            total_receber: 0,
            total_tempo: 0,
          };
          AgrupamentoAnalistaCliente.periodos?.push(AgrupamentoAnalistaperiodo);
        }

        // soma o valor distribuído no analista que recebeu
        (analista as any).total_receber += rateio.valor_liquido as any;
        atendimento.valor_receber += rateio.valor_liquido;
        AgrupamentoAnalistaCliente.total_receber += rateio.valor_liquido as any;
        AgrupamentoAnalistaperiodo.total_receber += rateio.valor_liquido as any;

        // abate de quem pagou
        if (rateio.tecnico_id !== atendimento.tecnico_id) {
          this.total_distribuicao += rateio.valor_liquido;
          (analista as any).total_distribuicao += rateio.valor_liquido as any;
          AgrupamentoAnalistaCliente.total_distribuicao +=
            rateio.valor_liquido as any;
          AgrupamentoAnalistaperiodo.total_distribuicao +=
            rateio.valor_liquido as any;

          (analistaAtendimento as any).total_distribuicao -=
            rateio.valor_liquido as any;
          (AgrupamentoCliente as any).total_distribuicao -=
            rateio.valor_liquido as any;
          (Agrupamentoperiodo as any).total_distribuicao -=
            rateio.valor_liquido as any;
        }
      });

      (analistaAtendimento as any).total_bruto +=
        atendimento.valor_bruto as any;
      (analistaAtendimento as any).total_imposto += atendimento.valor_imposto;
      (analistaAtendimento as any).total_tempo += atendimento.total_hours;

      (AgrupamentoCliente as any).total_bruto += atendimento.valor_bruto;
      (AgrupamentoCliente as any).total_imposto += atendimento.valor_imposto;
      (AgrupamentoCliente as any).total_tempo += atendimento.total_hours;

      (Agrupamentoperiodo as any).total_bruto += atendimento.valor_bruto;
      (Agrupamentoperiodo as any).total_imposto += atendimento.valor_imposto;
      (Agrupamentoperiodo as any).total_tempo += atendimento.total_hours;

      const conflito = this.atendimentos.find((atendBusca) => {
        return (
          atendBusca.id !== atendimento.id &&
          atendBusca.cliente_id === atendimento.cliente_id &&
          atendBusca.tecnico_id === atendimento.tecnico_id &&
          ((atendimento.data_inicio >= atendBusca.data_inicio &&
            atendimento.data_inicio < atendBusca.data_fim) ||
            (atendimento.data_fim > atendBusca.data_inicio &&
              atendimento.data_fim <= atendBusca.data_fim))
        );
      });
      (atendimento as any).conflicted =
        conflito && conflito.id !== atendimento.id;
    });
    // monta totais da tabela de analistas
    this.expandedRowsSubItens = {};
    this.analistas.forEach((x: any) => {
      (this.expandedRowsSubItens as any)[x.id] = {};

      this.total_bruto += x.total_bruto;
      this.total_tempo += x.total_tempo;
      this.total_imposto += x.total_imposto;
    });
    this.loading = false;
  }

  showDialogToAdd() {
    this.selectedAtendimento = new Atendimento({});
    (this.selectedAtendimento as any).cliente_id = this.selectedClienteObj.id;
    this.selectedAtendimento.periodo_id = this.selectedPeriodoObj.id;
    this.displayDialog = true;
  }

  onRowSelect() {
    if (this.selectedAtendimento?.id) {
      this.displayDialog = true;
    }
  }

  exportPdf() {
    let exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    exportColumns.push({
      title: 'Descrição',
      dataKey: 'descricao',
    });

    let atendimentos: any[] = JSON.parse(JSON.stringify(this.atendimentos));

    atendimentos.forEach((atendimento) => {
      let atOriginal = this.atendimentos.find((x) => {
        return x.id === atendimento.Id;
      });

      atendimento['data_inicio'] = (
        atOriginal as any
      ).data_inicio.toLocaleString('pt-BR') as any;

      atendimento['data_fim'] = (atOriginal as any).data_fim.toLocaleString(
        'pt-BR'
      ) as any;

      atendimento['valor_bruto'] = (
        atOriginal as any
      ).valor_bruto.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) as any;

      atendimento['valor_receber'] = (
        atOriginal as any
      ).valor_receber.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) as any;
    });

    const doc = new jsPDF({ orientation: 'l' });
    doc.text('Atendimentos - ' + this.selectedPeriodoObj.descricao, 20, 15);
    (doc as any)['autoTable'](exportColumns, atendimentos, { startY: 20 });
    doc.save('atendimentos_' + this.selectedPeriodoObj.descricao + '.pdf');
  }

  exportExcel() {
    let exportColumns = this.cols.map((col) => col.header);
    exportColumns.push('Descrição');

    import('xlsx').then((xlsx) => {
      //Had to create a new workbook and then add the header
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([]); // create worksheet

      xlsx.utils.sheet_add_aoa(ws, [exportColumns]);

      let exportData = this.atendimentos.map((atendimento: any) => {
        let row: any = {};
        this.cols.forEach((col) => {
          row[col.field] = atendimento[col.field];
        });
        row['descricao'] = atendimento['descricao'];
        return row;
      });

      //Starting in the second row to avoid overriding and skipping headers
      xlsx.utils.sheet_add_json(ws, exportData, {
        origin: 'A2',
        skipHeader: true,
      });

      xlsx.utils.book_append_sheet(wb, ws, 'Atendimentos');

      const excelBuffer: any = xlsx.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(
        excelBuffer,
        'atendimentos_' + this.selectedPeriodoObj.descricao
      );
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + ' ' + new Date().getTime() + EXCEL_EXTENSION);
  }

  navigate() {
    if (
      this.clienteRota !== '' + this.selectedCliente ||
      '' + this.periodoRota !== '' + this.selectedPeriodo
    ) {
      this.router.navigate([
        '/atendimento/' + this.selectedCliente + '/' + this.selectedPeriodo,
      ]);
    }
  }

  Filtrar(funcao: any, event: any) {
    funcao(event.target['value'], 'contains');
  }

  expandedKeys(expandedRowsSubItens: any, analista: any) {
    return expandedRowsSubItens[analista.id];
  }
}
