import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { User } from './user.model';
import { jwtDecode } from 'jwt-decode';

let authToken: string = '';

export function GetAuthToken() {
  return authToken;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedUser: string | undefined;
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly USER = 'JWT_USER';
  private activeRoute = 'JWT_ROUTE';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private _token: string | null = null;
  private _user: User = new User();

  constructor(private http: HttpClient, private router: Router) {
    this._user = JSON.parse(sessionStorage.getItem(this.USER) as any) as User;
    if (this._user) {
      authToken = sessionStorage.getItem(this.JWT_TOKEN) || '';
    }
  }

  loginSenha(
    usuario: string,
    senha: string,
    messageService: MessageService,
    sucessFunction: () => void
  ) {
    this.http
      .post<any>(`${environment.apiUrl}/users/login`, {
        email: usuario,
        password: senha,
      })
      .subscribe(
        (loginData: {
          error: boolean;
          success: boolean;
          access_token?: string;
        }) => {
          if (loginData.error) {
            messageService.add({
              severity: 'warn',
              summary: 'Erro login',
              detail: 'Usuário ou senha incorretos!',
            });
            return;
          } else {
            authToken = loginData.access_token || '';
            this.doLoginUser(jwtDecode(authToken));
            sucessFunction();
          }
        },
        (y) => {
          messageService.add({
            severity: 'warn',
            summary: 'Erro login',
            detail:
              y.status === 401
                ? 'Falha de autenticação para o usuário!'
                : 'Não foi possível obter o token de acesso ao ERP entre em contato com o suporte!',
          });
        }
      );
  }

  EnviaToken(
    usuario: string,
    messageService: MessageService,
    sucessFunction: () => void,
    errorFunction: () => void
  ) {
    this.http
      .post<any>(`${environment.apiUrl}/users/sendToken`, {
        email: usuario,
      })
      .subscribe(
        (loginData: {
          error: boolean;
          success: boolean;
          access_token?: string;
        }) => {
          if (loginData.error) {
            messageService.add({
              severity: 'warn',
              summary: 'Erro login',
              detail: 'Ocorreu um erro!',
            });
            errorFunction();
          } else {
            messageService.add({
              severity: 'success',
              summary: 'Token enviado',
              detail: 'Token enviado para o e-mail cadastrado!',
            });
            sucessFunction();
          }
        },
        (y) => {
          messageService.add({
            severity: 'warn',
            summary: 'Erro login',
            detail:
              y.status === 401
                ? 'Falha de autenticação para o usuário!'
                : 'Não foi possível obter o token de acesso ao ERP entre em contato com o suporte!',
          });
          errorFunction();
        }
      );
  }

  TrocarSenha(
    usuario: string,
    token: string,
    senha: string,
    messageService: MessageService,
    sucessFunction: () => void
  ) {
    this.http
      .post<any>(`${environment.apiUrl}/users/recovery`, {
        email: usuario,
        password: senha,
        recovery_code: token,
      })
      .subscribe(
        (loginData: {
          error: boolean;
          success: boolean;
          access_token?: string;
        }) => {
          if (loginData.error) {
            messageService.add({
              severity: 'warn',
              summary: 'Erro login',
              detail: 'Verifique seus dados!',
            });
            return;
          } else {
            authToken = loginData.access_token || '';
            this.doLoginUser(jwtDecode(authToken));
            sucessFunction();
          }
        },
        (y) => {
          messageService.add({
            severity: 'warn',
            summary: 'Erro login',
            detail: 'Falha de recuperação!',
          });
        }
      );
  }

  logout() {
    return this.doLogoutUser();
  }

  isLoggedIn() {
    return this._user && this._user.email;
  }

  getJwtToken() {
    this._token = this._token
      ? this._token
      : sessionStorage.getItem(this.JWT_TOKEN);
    return this._token;
  }

  getUser(): User {
    this._user = this._user
      ? this._user
      : (JSON.parse(sessionStorage.getItem(this.USER) as any) as User);
    return this._user;
  }

  private doLoginUser(user: User) {
    this.loggedUser = user.nome;
    this.storeTokens(authToken);
    this.storeUser(user);
    return;
  }

  private doLogoutUser() {
    this.loggedUser = undefined;
    this.removeTokens();
    this.router.navigate(['/atendimento']);
  }

  private storeUser(user: User) {
    this._user = user;
    sessionStorage.setItem(this.USER, JSON.stringify(user));
  }

  private storeTokens(token: string) {
    sessionStorage.setItem(this.JWT_TOKEN, token);
  }

  private removeTokens() {
    sessionStorage.removeItem(this.JWT_TOKEN);
    sessionStorage.removeItem(this.USER);
    this._token = null;
    this._user = new User();
  }

  setRoute(route: string) {
    this.activeRoute = route;
  }
  getRoute(): string {
    return this.activeRoute;
  }
}
