export class Atendimento {
  id: number = undefined as any;
  cliente_id: string = undefined as any;
  periodo_id: number = undefined as any;
  descricao_periodo: string = undefined as any;
  tecnico_id: number = undefined as any;
  nome_tecnico: string = undefined as any;
  hora_inicio: string = undefined as any;
  duracao: string = undefined as any;
  data_inicio: Date = undefined as any;
  data_fim: Date = undefined as any;
  descricao: string = undefined as any;
  total_hours: number = undefined as any;
  valor_hora: number = 0;
  status: string = undefined as any;
  solicitante: string = undefined as any;
  chamado: string = undefined as any;
  abonada: boolean = undefined as any;
  data_solicitacao: Date = undefined as any;
  distribuicao: number = undefined as any;
  valor_receber: number = 0;
  valor_imposto: number = 0;
  valor_bruto: number = 0;
  valor_tecnico: number = 0;
  filial_id: number = undefined as any;
  rateios: { tecnico_id: number; valor_liquido: number }[] = [];

  conflicted = false;

  nome_cliente?: string;

  constructor(object: Partial<Atendimento> = {}) {
    for (const i in object) {
      if (this.hasOwnProperty(i)) {
        if (
          [
            'valor_receber',
            'valor_liquido',
            'valor_bruto',
            'valor_tecnico',
            'valor_imposto',
            'valor_hora',
          ].includes(i) &&
          (object as any)[i]
        ) {
          (this as any)[i] = parseFloat((object as any)[i]);
        } else if (
          ['data_inicio', 'data_fim', 'data_solicitacao'].includes(i) &&
          (object as any)[i]
        ) {
          (this as any)[i] = new Date((object as any)[i]);
        } else {
          (this as any)[i] = (object as any)[i];
        }
      }
    }

    this.calcularDuracao();
    this.calcularValoresRateio();
  }

  private calcularDuracao() {
    if (this.data_inicio && this.data_fim) {
      const diff = Math.abs(
        this.data_fim.getTime() - this.data_inicio.getTime()
      );
      const hours = Math.floor(diff / 1000 / 60 / 60);
      const minutes = Math.floor((diff - hours * 1000 * 60 * 60) / 1000 / 60);
      this.duracao = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
      this.total_hours = hours + minutes / 60;
    }
  }

  private calcularValoresRateio() {
    this.valor_tecnico = 0;
    this.rateios.forEach((rateio) => {
      rateio.valor_liquido = parseFloat(rateio.valor_liquido.toString());
      if (rateio.tecnico_id === this.tecnico_id) {
        this.valor_tecnico += rateio.valor_liquido;
      }
    });

    if (this.valor_receber === 0) {
      this.rateios.forEach((rateio) => {
        this.valor_receber += rateio.valor_liquido;
      });
    }
  }
}
