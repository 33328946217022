import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientModule,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { AtendimentoListComponent } from './atendimento/atendimento-list/atendimento-list.component';
import { AtendimentoDetailComponent } from './atendimento/atendimento-detail/atendimento-detail.component';
import localept from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localept, 'pt');

import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DatePickerModule } from 'primeng/datepicker';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { TreeTableModule } from 'primeng/treetable';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ClienteListComponent } from './cliente/cliente-list/cliente-list.component';
import { DataViewModule } from 'primeng/dataview';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { periodoEditComponent } from './periodo/periodo-edit/periodo-edit.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToolbarModule } from 'primeng/toolbar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputMaskModule } from 'primeng/inputmask';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TecnicoListComponent } from './tecnico/tecnico-list/tecnico-list.component';
import { CriadorAssinaturaComponent } from '../assinatura/criador-assinatura.component';
import { ModeloAssinaturaComponent } from '../assinatura/modelo-assinatura.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './auth/auth.guard';
import { TokenInterceptor } from './auth/token.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FloatLabelModule } from 'primeng/floatlabel';
import { PasswordModule } from 'primeng/password';
import { TextareaModule } from 'primeng/textarea';
import { SelectModule } from 'primeng/select';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    AtendimentoListComponent,
    AtendimentoDetailComponent,
    ClienteListComponent,
    periodoEditComponent,
    TecnicoListComponent,
    CriadorAssinaturaComponent,
    ModeloAssinaturaComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    DialogModule,
    ButtonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    MultiSelectModule,
    DatePickerModule,
    ToastModule,
    InputMaskModule,
    EditorModule,
    InputNumberModule,
    InputTextModule,
    TreeTableModule,
    DataViewModule,
    TabViewModule,
    PanelModule,
    ToolbarModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    InputSwitchModule,
    TagModule,
    SelectButtonModule,
    FloatLabelModule,
    PasswordModule,
    SelectModule,
    TextareaModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'assinatura',
        component: CriadorAssinaturaComponent,
        pathMatch: 'full',
      },
      {
        path: 'atendimento',
        component: AtendimentoListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'atendimento/:cliente',
        component: AtendimentoListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'atendimento/:cliente/:periodo',
        component: AtendimentoListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cliente',
        component: ClienteListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tecnico',
        component: TecnicoListComponent,
        canActivate: [AuthGuard],
      },
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    CurrencyPipe,
    DatePipe,
    ConfirmationService,
    DecimalPipe,
    MessageService,
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Aura,
        options: {
          prefix: 'p',
          darkModeSelector: '.my-app-dark',
          cssLayer: false,
        },
      },
    }),
  ],
})
export class AppModule {}
