{
  "name": "bssys",
  "version": "10.1.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --verbose",
    "build": "ng build  --output-hashing=all",
    "build:prd": "ng build --configuration production  --output-hashing=all",
    "test": "ng test",
    "lint": "ng lint",
    "upd": "ng update @angular/core@18 @angular/cli@18 @angular/cdk@18 primeng@18 typescript@5.4",
    "serve:ssr:bssys": "node dist/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.3",
    "@angular/cdk": "^19.2.6",
    "@angular/common": "^19.2.3",
    "@angular/core": "^19.2.3",
    "@angular/forms": "^19.2.3",
    "@angular/platform-browser": "^19.2.3",
    "@angular/platform-browser-dynamic": "^19.2.3",
    "@angular/platform-server": "^19.2.3",
    "@angular/router": "^19.2.3",
    "@angular/ssr": "^19.2.4",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@primeng/themes": "^19.0.10",
    "aspnet-prerendering": "^3.0.1",
    "bootstrap": "^5.3.3",
    "core-js": "^3.41.0",
    "counterup": "^1.0.2",
    "easing": "^1.2.1",
    "express": "^4.21.2",
    "file-saver": "^2.0.5",
    "html-encoder-decoder": "^1.3.11",
    "http": "0.0.1-security",
    "isotope": "1.0.0-alpha.3",
    "jquery": "^3.7.1",
    "jquery-migrate": "^3.5.2",
    "js": "^0.1.0",
    "jspdf": "^3.0.1",
    "jspdf-autotable": "^5.0.2",
    "jwt-decode": "^4.0.0",
    "lightbox": "^0.5.0",
    "oidc-client": "^1.11.5",
    "owlcarousel": "^1.3.3",
    "parser": "^0.1.4",
    "popper.js": "^1.16.1",
    "primeflex": "^4.0.0",
    "primeicons": "^7.0.0",
    "primeng": "^19.0.10",
    "quill": "^2.0.3",
    "quill-delta": "^5.1.0",
    "rxjs": "^7.8.2",
    "tokenizer": "^1.1.2",
    "tslib": "^2.8.1",
    "wowjs": "^1.1.3",
    "xlsx": "^0.18.5",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.4",
    "@angular/cli": "^19.2.4",
    "@angular/compiler-cli": "^19.2.3",
    "@angular/language-service": "^19.2.3",
    "@types/express": "^5.0.1",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "^5.1.7",
    "@types/jasminewd2": "^2.0.13",
    "@types/node": "^22.13.11",
    "@typescript-eslint/eslint-plugin": "^8.27.0",
    "@typescript-eslint/parser": "^8.27.0",
    "codelyzer": "^6.0.2",
    "eslint": "^9.23.0",
    "jasmine-core": "^5.6.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "typescript": "^5.8.2"
  },
  "optionalDependencies": {
    "node-sass": "^9.0.0",
    "protractor": "^7.0.0",
    "ts-node": "^10.9.2"
  }
}
