import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { periodoEditComponent } from '../../periodo/periodo-edit/periodo-edit.component';
import { Cliente } from '../../domains/cliente';
import { Periodo } from '../../domains/periodo';
import { SelectItem } from 'primeng/select';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Result } from '../../domains/result';

@Component({
  selector: 'app-cliente-list',
  templateUrl: './cliente-list.component.html',
  styleUrls: ['./cliente-list.component.css'],
  standalone: false,
})
export class ClienteListComponent implements OnInit {
  @ViewChild(periodoEditComponent)
  private periodoEditComponent: periodoEditComponent = null as any;

  cols: any[] = [];
  clientes: Cliente[] = [];
  periodos: Periodo[] = [];

  selectedCliente: Cliente = null as any;
  selectedPeriodo: Periodo = null as any;
  displayDialog: boolean = false;
  editMode: boolean = false;

  sortOptions: SelectItem[] = [];

  sortKey: string = '';

  sortField: string = '';

  sortOrder: number = 0;

  expandedRows = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private authorizeService: AuthService
  ) {}

  ngOnInit() {
    const profile = this.authorizeService.getUser();
    if (!profile || profile.perfil !== 'Administrador') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Você não tem permissão!',
      });
      this.router.navigate(['/atendimento']);
    } else {
      this.cols = [{ field: 'Nome', header: 'Nome' }];

      const url = environment.apiUrl + '/customers';

      this.http.get<Result<Cliente[]>>(url).subscribe(
        (res) => {
          this.clientes = res.data;
        },
        (error) => {
          console.log(error);
          if (error.status === 401) {
            this.authorizeService.logout();
          }
        }
      );
    }
  }

  selectCliente(event: Event, cliente: Cliente) {
    this.periodos = [];
    this.getperiodos(cliente.id as string);
    this.selectedCliente = cliente;
    this.displayDialog = true;
    event.preventDefault();
  }

  onSortChange(event: any) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  onDialogHide() {
    this.selectCliente = null as any;
  }

  getperiodos(cliente_id: string) {
    const url = environment.apiUrl + '/customers/' + cliente_id;
    this.http.get<Result<Cliente>>(url).subscribe(
      (res) => {
        this.periodos = res.data.periodos as any;
        this.selectedPeriodo = this.periodos[0];
      },
      (error) => {
        console.log(error);
        if (error.status === 401) {
          this.authorizeService.logout();
        }
      }
    );
  }

  deleteperiodo(periodo: Periodo) {
    this.editMode = true;
    this.periodoEditComponent.updateForm(periodo);
    this.periodoEditComponent.onDelete();
  }

  editperiodo(periodo: Periodo) {
    this.editMode = true;
    console.log(periodo);
    this.periodoEditComponent.updateForm(periodo);
    this.periodoEditComponent.displayperiodo = true;
  }

  showDialogToAdd() {
    this.editMode = false;
    this.periodoEditComponent.createForm();
    this.periodoEditComponent.displayperiodo = true;
  }

  Filtrar(funcao: any, event: any) {
    funcao(event.target['value']);
  }
}
