import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modelo-assinatura',
  templateUrl: './modelo-assinatura.html',
  standalone: false,
})
export class ModeloAssinaturaComponent {
  @Input()
  name: string = '';

  @Input()
  cargo: string = '';

  @Input()
  phone: string = '';

  @Input()
  mail: string = '';
}
