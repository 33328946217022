import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../auth/user.model';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
  standalone: false,
})
export class NavMenuComponent {
  public version: string = environment.version;
  isExpanded = false;
  public isAuthenticated: boolean;
  public userName: string;

  get profile(): User {
    return this.authorizeService.getUser();
  }

  constructor(private authorizeService: AuthService) {
    this.isAuthenticated = this.authorizeService.isLoggedIn() ? true : false;
    this.userName = this.authorizeService.getUser()?.nome;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    this.authorizeService.logout();
  }

  onClickMenu() {
    // Regular DOM
    let bodyClass = document.querySelector('.mobile-nav-active');
    let body = document.querySelector('body');
    let icon = document.querySelector('.mobile-nav-toggle i');
    let overly: any = document.querySelector('.mobile-nav-overly');

    if (bodyClass) {
      body?.classList.remove('mobile-nav-active');
      if (icon) {
        icon.classList.remove('fa-times');
        icon.classList.remove('fa-bars');
      }
      if (overly) {
        overly.style.display = 'none';
      }
    } else {
      body?.classList.add('mobile-nav-active');
      if (icon) {
        icon.classList.add('fa-times');
        icon.classList.add('fa-bars');
      }
      if (overly) {
        overly.style.display = 'block';
      }
    }
  }
}
